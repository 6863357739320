import { Col, Row } from "antd";

interface ISelectFollowOptionProps {
  followState: string;
  setFollowState: (value: string) => void;
}

export const SelectFollowOption: React.FC<ISelectFollowOptionProps> = ({
  followState,
  setFollowState,
}) => {
  return (
    <Row className="flex flex-row rounded-full bg-[#292D31] p-1 w-fit text-white">
      <Col
        className={`px-3.5 py-1.5 rounded-full ${
          followState === "following" && "bg-white text-[#1D1F22]"
        }`}
        onClick={() => setFollowState("following")}
      >
        Following
      </Col>
      <Col
        className={`px-3.5 py-1.5 rounded-full ${
          followState === "followers" && "bg-white text-[#1D1F22]"
        }`}
        onClick={() => setFollowState("followers")}
      >
        Followers
      </Col>
    </Row>
  );
};
