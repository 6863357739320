import {
  UpdatePasswordFormTypes,
  UpdatePasswordPageProps,
  useActiveAuthProvider,
  useParsed,
} from "@refinedev/core";
import { useTranslate, useUpdatePassword } from "@refinedev/core";
import {
  Button,
  Card,
  CardProps,
  Col,
  Form,
  FormProps,
  Input,
  Layout,
  LayoutProps,
  Row,
  Typography,
  theme,
} from "antd";
import React, { useEffect } from "react";

import { ThemedTitleV2 } from "@refinedev/antd";
import {
  bodyStyles,
  containerStyles,
  headStyles,
  layoutStyles,
  subTitleStyles,
  titleStyles,
} from "../styles";

type UpdatePasswordProps = UpdatePasswordPageProps<
  LayoutProps,
  CardProps,
  FormProps
>;

/**
 * **refine** has update password page form which is served on `/update-password` route when the `authProvider` configuration is provided.
 *
 * @see {@link https://refine.dev/docs/ui-frameworks/antd/components/antd-auth-page/#update-password} for more details.
 */
export const UpdatePasswordPage: React.FC<UpdatePasswordProps> = ({
  wrapperProps,
  contentProps,
  renderContent,
  formProps,
  title,
}) => {
  const { token } = theme.useToken();
  const [form] = Form.useForm<UpdatePasswordFormTypes>();
  const translate = useTranslate();
  const authProvider = useActiveAuthProvider();
  const { mutate: updatePassword, isLoading } =
    useUpdatePassword<UpdatePasswordFormTypes>({
      v3LegacyAuthProviderCompatible: Boolean(authProvider?.isLegacy),
    });
  const { params } = useParsed();

  const PageTitle =
    title === false ? null : (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "32px",
          fontSize: "20px",
        }}
      >
        {title ?? <ThemedTitleV2 collapsed={false} />}
      </div>
    );

  const CardTitle = (
    <>
      <Typography.Title
        level={3}
        style={{
          color: token.colorPrimaryTextHover,
          ...titleStyles,
        }}
      >
        LeyLine
      </Typography.Title>
      <Typography.Title
        level={5}
        style={{
          color: token.colorPrimaryTextHover,
          ...subTitleStyles,
        }}
      >
        Reset Your Password
      </Typography.Title>
    </>
  );

  const CardContent = (
    <Card
      title={CardTitle}
      headStyle={headStyles}
      bodyStyle={bodyStyles}
      style={{
        ...containerStyles,
        backgroundColor: token.colorBgElevated,
      }}
      {...(contentProps ?? {})}
    >
      <Form<UpdatePasswordFormTypes>
        layout="vertical"
        form={form}
        onFinish={(values) => updatePassword(values)}
        requiredMark={false}
        {...formProps}
        initialValues={{
          email: params?.email,
        }}
      >
        <Form.Item
          name="email"
          label={translate("pages.register.email", "Email")}
        >
          <Input
            size="large"
            placeholder={translate("pages.register.fields.email", "Email")}
            autoComplete={"off"}
            disabled
          />
        </Form.Item>

        <Form.Item
          name="password"
          label={translate(
            "pages.updatePassword.fields.password",
            "New Password",
          )}
          rules={[
            {
              required: true,
              validator: (_, value) => {
                const errors = [];
                if (value && value.length < 8) errors.push("Min 8 chars");
                if (!/\d/.test(value)) errors.push("1 digit");
                if (!/[A-Z]/.test(value)) errors.push("1 uppercase letter");
                if (!/[a-z]/.test(value)) errors.push("1 lowercase letter");
                if (!/[ !@#$%&'()*+,-.\/[\\\]^_`{|}~]/.test(value))
                  errors.push("1 special char");

                if (errors.length > 0) {
                  return Promise.reject(new Error(errors.join(", ")));
                }
                return Promise.resolve();
              },
            },
          ]}
          style={{ marginBottom: "12px" }}
        >
          <Input.Password
            placeholder="New Password"
            size="large"
            allowClear
            autoComplete={"off"}
          />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          label={translate(
            "pages.updatePassword.fields.confirmPassword",
            "Confirm New Password",
          )}
          hasFeedback
          dependencies={["password"]}
          rules={[
            {
              required: true,
              message: translate(
                "pages.register.errors.confirmPassword",
                "Please confirm your password!",
              ),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(
                    translate(
                      "pages.register.errors.passwordMatch",
                      "The two passwords do not match",
                    ),
                  ),
                );
              },
            }),
          ]}
        >
          <Input.Password
            placeholder="Confirm Password"
            size="large"
            autoComplete="off"
          />
        </Form.Item>
        <Form.Item
          style={{
            marginBottom: 0,
          }}
        >
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            loading={isLoading}
            block
          >
            Continue
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );

  return (
    <Layout style={layoutStyles} {...(wrapperProps ?? {})}>
      <Row
        justify="center"
        align="middle"
        style={{
          padding: "16px 0",
          minHeight: "100dvh",
        }}
      >
        <Col xs={22}>
          {renderContent ? (
            renderContent(CardContent, PageTitle)
          ) : (
            <>
              {PageTitle}
              {CardContent}
            </>
          )}
        </Col>
      </Row>
    </Layout>
  );
};
