import { StatusValueEnum, priorityValueEnum } from "@/utils/enum";

export type TaskStatusType =
  | StatusValueEnum.NotReady
  | StatusValueEnum.Assign
  | StatusValueEnum.InProgress
  | StatusValueEnum.Dailies
  | StatusValueEnum.Feedback
  | StatusValueEnum.Publish
  | StatusValueEnum.Approved
  | StatusValueEnum.Finished
  | StatusValueEnum.Hold
  | StatusValueEnum.Cut
  | StatusValueEnum.Fix
  | StatusValueEnum.Update;

export type PriorityType =
  | priorityValueEnum.None
  | priorityValueEnum.Low
  | priorityValueEnum.Normal
  | priorityValueEnum.High
  | priorityValueEnum.Urgent
  | priorityValueEnum.Immediate;

export interface OptionItemType {
  label: string;
  value: string | number;
  disabled?: boolean;
}

export enum RoleType {
  company = "Company",
  individual = "Individual",
}
