import { UserType } from "@/__generated__/graphql";
import { ArtistCollections } from "@/components/discover/artistDetail/ArtistCollections";
import { ArtistFollow } from "@/components/discover/artistDetail/ArtistFollow";
import { ArtistIntroduction } from "@/components/discover/artistDetail/ArtistIntroduction";
import { ArtistLikes } from "@/components/discover/artistDetail/ArtistLikes";
import { ArtistMenu } from "@/components/discover/artistDetail/ArtistMenu";
import { ArtistResume } from "@/components/discover/artistDetail/ArtistResume";
import { AuthorPortfolio } from "@/components/discover/artistDetail/AuthorPortfolio";
import { CompanyIntroduction } from "@/components/discover/artistDetail/CompanyIntroduction";
import { CompanyJobs } from "@/components/discover/artistDetail/CompanyJobs";
import { IIdentity, IUserInfo } from "@/interfaces";
import { getCompanyInfoAPI, getUserInfoAPI } from "@/pages/discover/api";
import { ICompany } from "@/pages/discover/types";
import { useGetIdentity, useParsed } from "@refinedev/core";
import { Col } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

const getUserRoleAndType = (
  searchParams: URLSearchParams,
  pathname?: string,
) => {
  let role: string;
  let type: string;
  if (pathname === "/users/profile") {
    role = UserType.Individual;
    type = "myself";
  } else if (pathname === "/companies" || pathname === "/companies/homepage") {
    role = UserType.Company;
    type = "myself";
  } else {
    role = searchParams.get("role") || UserType.Individual;
    type = "artist";
  }
  return [role, type];
};

export const ArtistPortfolio = () => {
  const { id } = useParams();
  const location = useLocation();
  const { pathname } = useParsed();
  const searchParams = new URLSearchParams(location.search);
  const [userRole, userType] = getUserRoleAndType(searchParams, pathname);
  const { data: user } = useGetIdentity<IIdentity>();
  const userId = user?.id || "";
  const artistId = userType === "myself" ? userId : id || "";
  const [authorTabState, setAuthorTabState] = useState<string>("portfolio");
  const [userInfo, setUserInfo] = useState<IUserInfo | null>(null);
  const [companyInfo, setCompanyInfo] = useState<ICompany | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const getUserInfo = useCallback(async () => {
    if (userRole === UserType.Company) {
      const response = await getCompanyInfoAPI(artistId);
      setCompanyInfo(response);
    } else if (userRole === UserType.Individual) {
      const response = await getUserInfoAPI(artistId);
      setUserInfo(response);
      console.log("getUserInfo", response);
    }
  }, [artistId, userRole]);
  // biome-ignore lint:
  useEffect(() => {
    setAuthorTabState("portfolio");
  }, [id]);

  useEffect(() => {
    getUserInfo();
  }, [getUserInfo]);

  const renderContent = () => {
    switch (authorTabState) {
      case "portfolio":
        return (
          <AuthorPortfolio
            containerRef={containerRef}
            author_id={artistId}
            userId={userId}
            user_role={userRole}
          />
        );
      case "resume":
        return (
          userInfo && (
            <ArtistResume
              user_role={userRole}
              artistUserInfo={userInfo}
              userId={userId}
              authorId={artistId}
            />
          )
        );
      case "introduction":
        return (
          companyInfo && (
            <CompanyIntroduction
              user_role={userRole}
              companyInfo={companyInfo}
              authorId={artistId}
              userId={userId}
            />
          )
        );
      case "jobs":
        return (
          <CompanyJobs
            containerRef={containerRef}
            userId={userId}
            authorId={artistId}
            user_role={userRole}
          />
        );
      case "likes":
        return (
          <ArtistLikes
            containerRef={containerRef}
            author_id={artistId}
            user_role={userRole}
          />
        );
      case "collections":
        return (
          <ArtistCollections
            containerRef={containerRef}
            author_id={artistId}
            userId={userId}
            user_role={userRole}
          />
        );
      case "follow":
        return (
          <ArtistFollow
            containerRef={containerRef}
            author_id={artistId}
            user_role={userRole}
          />
        );
      default:
        return null;
    }
  };

  const artistProfile =
    userRole === UserType.Company ? companyInfo : userInfo?.profile;

  return (
    <Col
      ref={containerRef}
      className="py-5 px-10 2xl:px-36 lg:px-24 sm:px-12 w-full h-full overflow-auto"
    >
      <div className="w-full">
        {artistProfile && (
          <ArtistIntroduction
            artistUserInfo={artistProfile}
            artistId={artistId}
            user_role={userRole}
            refresh={getUserInfo}
          />
        )}
      </div>
      <div className="w-full">
        <ArtistMenu
          setAuthorTab={setAuthorTabState}
          authorTab={authorTabState}
          user_role={userRole}
        />
      </div>
      <div className="flex flex-col w-full">{renderContent()}</div>
    </Col>
  );
};
