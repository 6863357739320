import {
  UserType,
  useSetRemoteCustomDataConversationMutation,
} from "@/__generated__/graphql";
import {
  ActiveFavouriteIcon,
  ActiveHeartIcon,
  CloseIcon,
  CompanyIcon,
  DefaultUserIcon,
  FollowIcon,
} from "@/components/customComponents/CustomIcons";
import { CollapseIcon } from "@/components/customComponents/CustomIcons";
import { ExpandIcon } from "@/components/customComponents/CustomIcons";
import { FollowingIcon } from "@/components/customComponents/CustomIcons";
import { BookmarkSetting } from "@/components/discover/workDetail/BookmarkSetting";
import { CommentDetail } from "@/components/discover/workDetail/CommentDetail";
import { CommentInput } from "@/components/discover/workDetail/CommentInput";
import { JobPanel } from "@/components/discover/workDetail/JobPanel";
import { PortfolioActionAlarm } from "@/components/discover/workDetail/PortfolioActionAlarm";
import { useHasCompanyRole } from "@/hooks";
import { useCheckAuth } from "@/hooks/useCheckAuth";
import { IIdentity, IProfile } from "@/interfaces";
import {
  commentListCountAPI,
  createArtworkAPI,
  createCollectionAPI,
  createFollowAPI,
  createLikeAPI,
  getCommentListAPI,
  getGuestCommentListAPI,
  getGuestCommentListCountAPI,
  removeCollectionAPI,
  removeFollowAPI,
  removeLikeAPI,
  updateArtworkAPI,
  useUserProfile,
} from "@/pages/discover/api";
import {
  IArtworkItem,
  ICompany,
  ICreateArtworkFormValues,
  IJobData,
  IRequestCommentData,
  ISetReplyCommentInfo,
  ISubmitArtworkData,
  IUser,
} from "@/pages/discover/types";
import { useArtworkStore, useGlobalStore } from "@/stores";
import { getImgUrl } from "@/utils/common";
import { formatToLongNoSecondStyle } from "@/utils/date";
import { isImageFiles } from "@/utils/identifyFiles";
import { judgeIsCompanyRole, setConversationCustomData } from "@/utils/imUtils";
import { padImageUrl } from "@/utils/upload";
import Icon, {
  PlusOutlined,
  HeartOutlined,
  MessageOutlined,
  StarOutlined,
  ShareAltOutlined,
  EnvironmentFilled,
  CloseOutlined,
  CheckOutlined,
  EditOutlined,
  EllipsisOutlined,
  MessageFilled,
} from "@ant-design/icons";
import { useGetIdentity } from "@refinedev/core";
import { useInfiniteScroll } from "ahooks";
import {
  Avatar,
  Button,
  Col,
  ConfigProvider,
  Dropdown,
  Row,
  Skeleton,
  Space,
  Typography,
  message,
} from "antd";
import { update } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { Link, redirect, useLocation, useNavigate } from "react-router-dom";

interface ICommentPanelProps {
  modal?: boolean;
  artwork: IArtworkItem;
  preview: boolean;
}

interface IAlarmTimer {
  id: number;
  type: string;
  timeout: number;
}

export const CommentPanel: React.FC<ICommentPanelProps> = ({
  modal,
  artwork,
  preview,
}) => {
  const { currentRole, chatClient, updateLoading } = useGlobalStore(
    (state) => state,
  );
  const location = useLocation();
  const {
    artworkDetailEditMode,
    artworkDetailEditArtworkId,
    updateArtworkDetailModalVisible,
    updateArtworkDetailArtworkId,
    updateArtworkDetailEditMode,
    updateArtworkDetailEditArtworkId,
  } = useArtworkStore((state) => state);
  const [jobPanelVisible, setJobPanelVisible] = useState(false);
  const [selectJob, setSelectJob] = useState<IJobData>();
  const containerRef = React.useRef<HTMLDivElement>(null);
  const edit_mode = artworkDetailEditMode ? "true" : "false";
  const artwork_id = artworkDetailEditArtworkId ?? "";
  const { data: user } = useGetIdentity<IIdentity>();
  const user_id = user?.id || "";
  const { data } = useUserProfile(user_id);
  const profile = data?.data.profile as IProfile;
  const { checkAuth } = useCheckAuth();
  const isCompanyRole = judgeIsCompanyRole(currentRole);
  const { companyInfo } = useHasCompanyRole(isCompanyRole);
  const navigate = useNavigate();
  const [isCommentInputVisible, setIsCommentInputVisible] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [isFollowed, setIsFollowed] = useState(false);
  const [isFavourited, setIsFavourited] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [actionType, setActionType] = useState("");
  const [commentReplyState, setCommentReplyState] = useState(false);
  const [replyCommentInfo, setReplyCommentInfo] =
    useState<ISetReplyCommentInfo>({
      parent_id: "",
      user_name: "",
      content: "",
    });
  const [linkCopyState, setLinkCopyState] = useState(false);
  const [bookmarkTitle, setBookmarkTitle] = useState<string>("");
  const [bookmarkState, setBookmarkState] = useState(false);
  const [alarms, setAlarms] = useState<IAlarmTimer[]>([]);
  const [commentCount, setCommentCount] = useState(0);
  const [reloadState, setReloadState] = useState("");
  const [requestCommentData, setRequestCommentData] =
    useState<IRequestCommentData>({
      page: 1,
      size: 10,
    });
  const [setRemoteCustomDataMutation] =
    useSetRemoteCustomDataConversationMutation();

  const handleNavigateBack = () => {
    if (modal) {
      updateArtworkDetailModalVisible(false);
    } else {
      navigate("/");
    }
  };

  const handleCollapseToggle = () => setIsCollapsed((prevState) => !prevState);

  const handleCancelClick = () => {
    localStorage.setItem("workState", "true");
    updateArtworkDetailModalVisible(false);
  };

  const handleShareClick = () => {
    const currentLink = `/mktp/discover/portfolio/${artwork.id}`;
    navigator.clipboard
      .writeText(window.location.host + currentLink)
      .then(() => {
        showAlarm("link");
        setLinkCopyState(true);
        isCollapsed && message.success("Link copied to clipboard");
      })
      .catch((err) => {
        message.error("Failed to copy link to clipboard");
      });
  };

  const goToAuthorInfoPage = (artwork: IArtworkItem) => {
    if (location.pathname === `/discover/author/${artwork.user_id}`) {
      updateArtworkDetailArtworkId("");
      updateArtworkDetailModalVisible(false);
      updateArtworkDetailEditMode(false);
      updateArtworkDetailEditArtworkId("");
    }
    navigate(
      `/discover/author/${preview ? user_id : artwork.user_id}?role=${
        artwork.id === "preview" ? currentRole : artwork.role
      }`,
    );
  };

  const CollapseIM = () => {
    setIsCollapsed(false);
    setJobPanelVisible(true);
  };

  const getCommentList = async (page: number) => {
    if (!currentRole) return { list: [], nextPage: undefined };
    if (artwork.id !== "preview") {
      const response = await getCommentListAPI(artwork.id, currentRole, {
        ...requestCommentData,
        page,
      });
      return {
        list: response.list,
        nextPage:
          response.page < response.pages ? response.page + 1 : undefined,
      };
    }
    return {
      list: [],
      nextPage: undefined,
    };
  };

  const getGuestCommentList = async (page: number) => {
    if (!currentRole) return { list: [], nextPage: undefined };
    if (artwork.id !== "preview") {
      const response = await getGuestCommentListAPI(artwork.id, {
        ...requestCommentData,
        page,
      });
      return {
        list: response.list,
        nextPage:
          response.page < response.pages ? response.page + 1 : undefined,
      };
    }
    return {
      list: [],
      nextPage: undefined,
    };
  };

  const { data: infiniteData } = useInfiniteScroll(
    (d) => {
      if (!data) {
        return getGuestCommentList(d ? d.nextPage : 1);
      }
      return getCommentList(d ? d.nextPage : 1);
    },
    {
      target: containerRef,
      isNoMore: (d) => !d?.nextPage,
      reloadDeps: [artwork, reloadState, currentRole],
    },
  );

  const getCommentCount = useCallback(async () => {
    if (artwork.id !== "preview") {
      const response = await commentListCountAPI(artwork.id);
      setCommentCount(response);
    }
  }, [artwork]);

  const getGuestCommentCount = useCallback(async () => {
    if (artwork.id !== "preview") {
      const response = await getGuestCommentListCountAPI(artwork.id);
      setCommentCount(response);
    }
  }, [artwork]);

  useEffect(() => {
    if (!data) {
      getGuestCommentCount();
    } else {
      getCommentCount();
    }
  }, [getCommentCount, getGuestCommentCount, data]);

  useEffect(() => {
    artwork.is_liked ? setIsLiked(true) : setIsLiked(false);
    artwork.is_favorited ? setIsFavourited(true) : setIsFavourited(false);
    if (artwork.role === UserType.Individual) {
      (artwork.author as IUser)?.is_following
        ? setIsFollowed(true)
        : setIsFollowed(false);
    } else {
      (artwork.author as ICompany)?.is_following
        ? setIsFollowed(true)
        : setIsFollowed(false);
    }
  }, [artwork]);

  const onLikeClick = async () => {
    if (artwork.id === "preview" || !currentRole) {
      message.error("Failed to remove like");
      return;
    }
    if (modal) {
      await checkAuth();
    } else {
      if (!profile) {
        navigate(`/login?to=/discover/portfolio/${artwork.id}`);
        return;
      }
    }
    if (isLiked) {
      const response =
        artwork.id !== "preview"
          ? await removeLikeAPI(artwork.id, "ARTWORK", currentRole)
          : await removeLikeAPI(artwork_id, "ARTWORK", currentRole);
      if (response === "success") {
        message.success("Like removed successfully");
        artwork.liked_count = artwork.liked_count - 1;
        setIsLiked(false);
      } else {
        message.error("Failed to remove like");
      }
    } else {
      const response =
        artwork.id !== "preview"
          ? await createLikeAPI(artwork.id, "ARTWORK", currentRole)
          : await createLikeAPI(artwork_id, "ARTWORK", currentRole);
      if (response.id) {
        message.success("Like added successfully");
        setIsLiked(true);
        showAlarm("like");
        artwork.liked_count = artwork.liked_count + 1;
      } else {
        message.error("Failed to add like");
      }
    }
  };

  const onCollapseCommentLCick = () => {
    if (artwork.id === "preview") {
      message.error("Failed to add comment");
      return;
    }
    setIsCollapsed(false);
    setIsCommentInputVisible(true);
  };

  const onCollapseFavouriteClick = () => {
    if (artwork.id === "preview") {
      message.error("Failed to add favourite");
      return;
    }
    setIsCollapsed(false);
    onFavouriteClick();
  };

  const onFavouriteClick = async () => {
    if (!currentRole) {
      message.error("Failed to add favourite");
      return;
    }
    if (modal) {
      await checkAuth();
    } else {
      if (!profile) {
        navigate(`/login?to=/discover/portfolio/${artwork.id}`);
        return;
      }
    }
    if (isFavourited) {
      const response = await removeCollectionAPI(artwork.id, currentRole);
      if (response === "success") {
        message.success("Favourite removed successfully");
        setIsFavourited(false);
        artwork.favorited_count = artwork.favorited_count - 1;
      } else {
        message.error("Failed to remove favourite");
      }
    } else {
      const response = await createCollectionAPI(artwork.id, currentRole);
      if (response.id) {
        message.success("Favourite added successfully");
        setIsFavourited(true);
        showAlarm("collection");
        artwork.favorited_count = artwork.favorited_count + 1;
      } else {
        message.error("Failed to add favourite");
      }
    }
  };

  const showAlarm = (type: string, bookmarkTitle?: string) => {
    setBookmarkTitle(bookmarkTitle || "");
    setActionType(type);
    const id = Date.now();
    setAlarms((prevAlarms) => [...prevAlarms, { id, type, timeout: 2000 }]);
    setTimeout(() => {
      setAlarms((prevAlarms) => prevAlarms.filter((alarm) => alarm.id !== id));
    }, 3000);
  };

  const onUnFollowing = async (id?: string, user_role?: string) => {
    if (!currentRole || !id || !user_role) return;
    if (modal) {
      await checkAuth();
    } else {
      if (!profile) {
        navigate(`/login?to=/discover/portfolio/${artwork.id}`);
        return;
      }
    }
    await removeFollowAPI(currentRole, updateLoading, id, user_role);
    setIsFollowed(false);
  };

  const onFollowing = async (id?: string, user_role?: string) => {
    if (!currentRole || !id || !user_role) return;
    if (modal) {
      await checkAuth();
    } else {
      if (!profile) {
        navigate(`/login?to=/discover/portfolio/${artwork.id}`);
        return;
      }
    }
    await createFollowAPI(currentRole, updateLoading, id, user_role);
    setIsFollowed(true);
  };

  const coverPicture = (data: string[]) => {
    for (const media of data) {
      if (isImageFiles(media)) {
        return media;
      }
    }
    return "";
  };

  const handleSubmitClick = async () => {
    const values: ICreateArtworkFormValues = JSON.parse(
      localStorage.getItem("previewValues") || "{}",
    );
    const medias: string[] = [];
    let cover_picture = "";
    for (const file of values.mediaFiles) {
      if (file.type) {
        medias.push(padImageUrl(file.response.id));
      } else {
        medias.push(file.name);
      }
    }
    cover_picture = await coverPicture(medias);

    const submitArtWorkData: ISubmitArtworkData = {
      title: values.artworkTitle ? values.artworkTitle : "",
      description: values.artworkDescription ? values.artworkDescription : "",
      tags: values.tags ? values.tags : [],
      folder_id: values.folder,
      cover_picture: cover_picture,
      medias: medias,
      is_published: values.publishStatus,
      type: values.type,
    };
    if (edit_mode === "true" && artwork_id !== null) {
      const response = await updateArtworkAPI(submitArtWorkData, artwork_id);
      response !== null && message.success("Artwork updated successfully!");
    } else {
      if (!currentRole) return;
      const response = await createArtworkAPI(
        updateLoading,
        submitArtWorkData,
        currentRole,
      );
      response !== null && message.success("Artwork submitted successfully!");
    }
    localStorage.setItem("workState", "false");
    navigate("/portfolio/home");
    updateArtworkDetailModalVisible(false);
  };

  const handlOwnArtworkEdit = () => {
    navigate(`/portfolio/create?edit_mode=true&artwork_id=${artwork.id}`);
  };

  const goToChatPage = async (selectJob?: IJobData) => {
    const opponentImId = `${artwork.user_id}_${artwork.role?.toLowerCase()}`;
    await setConversationCustomData(
      chatClient,
      setRemoteCustomDataMutation,
      opponentImId,
      {
        jInfo: {
          // TODO: Just fill in these three fields
          id: selectJob?.id, // job_id,
          titl: selectJob?.title, // job_title,
          pName: selectJob?.project_name, // project_name,
          role: 2,
        },
      },
    );
    navigate("/enterprises/chat", {
      state: {
        opponentImId,
        activeTab: 2,
      },
    });
  };

  useEffect(() => {
    if (selectJob !== undefined) {
      goToChatPage(selectJob);
    }
  }, [selectJob, goToChatPage]);

  const renderCollapsedCompanyContent = () => (
    <Row className="p-6 flex flex-col items-center">
      <Col className="relative inline-block">
        <div className="relative">
          <Avatar
            size={44}
            className="object-cover border-[2px] border-solid border-[#03B3B4]"
            src={
              getImgUrl(
                preview
                  ? isCompanyRole
                    ? companyInfo?.extended_info?.logo_url
                    : profile?.avatar_url
                  : (artwork.author as ICompany)?.logo_url,
              ) || <DefaultUserIcon />
            }
          />
          <Space className="absolute z-10 left-1/2 top-[-15%] transform -translate-x-1/2">
            <CompanyIcon width="18px" height="18px" />
          </Space>
        </div>
        {artwork.user_id !== user_id &&
          artwork.id !== "preview" &&
          (isFollowed ? (
            <CheckOutlined
              onClick={() => onUnFollowing(artwork.user_id, artwork.role)}
              className="text-[#ff4d4f] bg-white w-6 h-6 rounded-full flex items-center justify-center absolute -translate-y-[50%] translate-x-[40%]"
            />
          ) : (
            <PlusOutlined
              onClick={() => onFollowing(artwork.user_id, artwork.role)}
              className="bg-[#ff4d4f] text-white w-6 h-6 rounded-full flex items-center justify-center absolute -translate-y-[50%] translate-x-[40%]"
            />
          ))}
      </Col>
      {artwork.user_id === user_id && artwork.role === currentRole && (
        <Col>
          <EditOutlined
            className="px-2.5 pt-4 text-white rounded-full text-xl"
            onClick={handlOwnArtworkEdit}
          />
        </Col>
      )}
      {currentRole === UserType.Company &&
        artwork.user_id !== user_id &&
        artwork.id !== "preview" && (
          <Col className="mt-8">
            <MessageFilled
              className="text-white rounded-full text-4xl"
              onClick={CollapseIM}
            />
          </Col>
        )}
      <Col className="mt-8">
        <Row className="flex flex-col items-center gap-[22px]">
          <Col>
            {isLiked ? (
              <Icon
                component={ActiveHeartIcon}
                className="p-2.5 bg-[#404346] text-white rounded-full text-xl"
                onClick={onLikeClick}
              />
            ) : (
              <HeartOutlined
                className="p-2.5 bg-[#606464] text-white rounded-full text-xl"
                onClick={onLikeClick}
              />
            )}
          </Col>
          <Col>
            {isFavourited ? (
              <Icon
                component={ActiveFavouriteIcon}
                className="p-2.5 bg-[#404346] text-white rounded-full text-xl"
                onClick={onCollapseFavouriteClick}
              />
            ) : (
              <StarOutlined
                className="p-2.5 bg-[#606464] text-white rounded-full text-xl"
                onClick={onCollapseFavouriteClick}
              />
            )}
          </Col>
          <Col>
            <MessageOutlined
              className="p-2.5 bg-[#606464] text-white rounded-full text-xl"
              onClick={onCollapseCommentLCick}
            />
          </Col>
          <Col>
            {linkCopyState ? (
              <ShareAltOutlined
                className="p-2.5 bg-[#404346] text-white rounded-full text-xl"
                onClick={handleShareClick}
              />
            ) : (
              <ShareAltOutlined
                className="p-2.5 bg-[#606464] text-white rounded-full text-xl"
                onClick={handleShareClick}
              />
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );

  const renderCollapsedIndividualContent = () => (
    <Row className="p-6 flex flex-col items-center">
      <Col className="relative inline-block">
        <Avatar
          className="object-cover"
          size={44}
          src={
            getImgUrl(
              preview
                ? isCompanyRole
                  ? companyInfo?.extended_info?.logo_url
                  : profile?.avatar_url
                : (artwork.author as IUser)?.avatar_url,
            ) || <DefaultUserIcon />
          }
        />
        {artwork.user_id !== user_id &&
          artwork.id !== "preview" &&
          (isFollowed ? (
            <CheckOutlined
              onClick={() => onUnFollowing(artwork.user_id, artwork.role)}
              className="text-[#ff4d4f] bg-white w-6 h-6 rounded-full flex items-center justify-center absolute -translate-y-[50%] translate-x-[40%]"
            />
          ) : (
            <PlusOutlined
              onClick={() => onFollowing(artwork.user_id, artwork.role)}
              className="bg-[#ff4d4f] text-white w-6 h-6 rounded-full flex items-center justify-center absolute -translate-y-[50%] translate-x-[40%]"
            />
          ))}
      </Col>

      {artwork.user_id === user_id && artwork.role === currentRole && (
        <Col>
          <EditOutlined
            className="px-2.5 pt-4 text-white rounded-full text-xl"
            onClick={handlOwnArtworkEdit}
          />
        </Col>
      )}
      {currentRole === UserType.Company &&
        artwork.user_id !== user_id &&
        artwork.id !== "preview" && (
          <Col className="mt-8">
            <MessageFilled
              className="text-white rounded-full text-4xl"
              onClick={CollapseIM}
            />
          </Col>
        )}
      <Col className="mt-8">
        <Row className="flex flex-col items-center gap-[22px]">
          <Col>
            {isLiked ? (
              <Icon
                component={ActiveHeartIcon}
                className="p-2.5 bg-[#404346] text-white rounded-full text-xl"
                onClick={onLikeClick}
              />
            ) : (
              <HeartOutlined
                className="p-2.5 bg-[#606464] text-white rounded-full text-xl"
                onClick={onLikeClick}
              />
            )}
          </Col>
          <Col>
            {isFavourited ? (
              <Icon
                component={ActiveFavouriteIcon}
                className="p-2.5 bg-[#404346] text-white rounded-full text-xl"
                onClick={onCollapseFavouriteClick}
              />
            ) : (
              <StarOutlined
                className="p-2.5 bg-[#606464] text-white rounded-full text-xl"
                onClick={onCollapseFavouriteClick}
              />
            )}
          </Col>
          <Col>
            <MessageOutlined
              className="p-2.5 bg-[#606464] text-white rounded-full text-xl"
              onClick={onCollapseCommentLCick}
            />
          </Col>
          <Col>
            {linkCopyState ? (
              <ShareAltOutlined
                className="p-2.5 bg-[#404346] text-white rounded-full text-xl"
                onClick={handleShareClick}
              />
            ) : (
              <ShareAltOutlined
                className="p-2.5 bg-[#606464] text-white rounded-full text-xl"
                onClick={handleShareClick}
              />
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );

  const renderExpandedCompanyContent = () => (
    <Row className="pt-6 flex flex-col flex-1 h-full overflow-hidden">
      <Col className="flex flex-row gap-4 pr-6 pb-6">
        <Col onClick={() => goToAuthorInfoPage(artwork)}>
          <div className="relative">
            <div className="relative">
              <Avatar
                size={60}
                className={`object-cover border-[2px] border-solid ${
                  isCompanyRole || !preview ? "border-[#03B3B4]" : ""
                }`}
                src={
                  getImgUrl(
                    preview
                      ? isCompanyRole
                        ? companyInfo?.extended_info?.logo_url
                        : profile?.avatar_url
                      : (artwork.author as ICompany)?.logo_url,
                  ) || <DefaultUserIcon />
                }
              />
              {(isCompanyRole || !preview) && (
                <Space className="absolute z-10 left-1/2 top-[-15%] transform -translate-x-1/2">
                  <CompanyIcon width="24px" height="24px" />
                </Space>
              )}
            </div>
          </div>
        </Col>
        {jobPanelVisible && (
          <div className="absolute top-8 right-10 z-50">
            <JobPanel
              setJobPanelVisible={setJobPanelVisible}
              setSelectJob={setSelectJob}
            />
          </div>
        )}
        <Row className="w-full text-base text-[#fff9] flex flex-col flex-1 overflow-hidden pt-1">
          <Col className="text-base font-bold flex flex-row justify-between">
            <Typography.Paragraph
              ellipsis={{ rows: 1 }}
              className="!mb-0 text-[22px] text-white"
            >
              {preview
                ? isCompanyRole
                  ? companyInfo?.extended_info?.shortname
                  : profile?.user_name
                : (artwork.author as ICompany)?.shortname}
            </Typography.Paragraph>
            {artwork.user_id !== user_id && artwork.id !== "preview" && (
              <div className="flex flex-row">
                {currentRole === UserType.Company && (
                  <MessageFilled
                    className="text-[#00ADAE] cursor-pointer"
                    onClick={() => setJobPanelVisible(true)}
                  />
                )}

                {isFollowed ? (
                  <Space
                    className="text-[#00ADAE] text-base ml-2 flex gap-1 items-center cursor-pointer"
                    onClick={() => onUnFollowing(artwork.user_id, artwork.role)}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                  >
                    {isHovered ? (
                      <>
                        <CloseOutlined />
                        <span className="text-[#00ADAE] custom-alibaba-font">
                          UnFollow
                        </span>
                      </>
                    ) : (
                      <>
                        <Icon component={FollowingIcon} />
                        <span className="text-[#00ADAE] custom-alibaba-font">
                          Following
                        </span>
                      </>
                    )}
                  </Space>
                ) : (
                  <Space
                    className="text-[#00ADAE] text-base ml-4 flex gap-1 items-center cursor-pointer"
                    onClick={() => onFollowing(artwork.user_id, artwork.role)}
                  >
                    <Icon component={FollowIcon} />
                    <span className="text-[#00ADAE] custom-alibaba-font">
                      Follow
                    </span>
                  </Space>
                )}
              </div>
            )}
          </Col>
          <Col className="text-sm overflow-hidden whitespace-nowrap text-ellipsis opacity-85 text-white">
            {preview
              ? isCompanyRole
                ? companyInfo?.extended_info?.industry
                  ? companyInfo?.extended_info?.scale
                    ? `${companyInfo?.extended_info?.industry} | ${companyInfo?.extended_info?.scale}`
                    : companyInfo?.extended_info?.industry
                  : companyInfo?.extended_info?.scale
                    ? companyInfo?.extended_info?.scale
                    : ""
                : profile?.introduction
              : (artwork.author as ICompany)?.industry
                ? (artwork.author as ICompany)?.scale
                  ? `${(artwork.author as ICompany)?.industry} | ${
                      (artwork.author as ICompany)?.scale
                    }`
                  : (artwork.author as ICompany)?.industry
                : (artwork.author as ICompany)?.scale
                  ? (artwork.author as ICompany)?.scale
                  : ""}
          </Col>
          {preview
            ? isCompanyRole
              ? companyInfo?.extended_info?.country && (
                  <Col className="text-sm text-[#606264] flex gap-1 items-center">
                    <EnvironmentFilled />
                    <Typography.Text className="text-[#606264] custom-alibaba-font">
                      {companyInfo?.extended_info?.country}
                    </Typography.Text>
                  </Col>
                )
              : (profile?.city || profile?.country) && (
                  <Col className="text-sm text-[#606264] flex gap-1 items-center">
                    <EnvironmentFilled />
                    <Typography.Text className="text-[#606264] custom-alibaba-font">
                      {profile?.city
                        ? profile?.country
                          ? `${profile?.city}, ${profile?.country}`
                          : profile?.city
                        : profile?.country}
                    </Typography.Text>
                  </Col>
                )
            : (artwork.author as ICompany)?.country && (
                <Col className="text-sm text-[#606264] flex gap-1 items-center">
                  <EnvironmentFilled />
                  <Typography.Text className="text-[#606264] custom-alibaba-font">
                    {(artwork.author as ICompany)?.country}
                  </Typography.Text>
                </Col>
              )}
        </Row>
      </Col>
      <Col className="flex flex-col gap-4">
        <Col>
          <Typography.Paragraph
            ellipsis={{ rows: 2 }}
            className="text-white font-bold text-2xl !mb-0"
          >
            {artwork?.title}
          </Typography.Paragraph>
        </Col>
        <Col>
          <Typography.Paragraph
            ellipsis={{ rows: 3 }}
            className="text-white opacity-85 text-base !mb-2"
          >
            {artwork?.description}
          </Typography.Paragraph>
          <Row className="flex gap-2 mb-2">
            {artwork?.tags?.map((tag) => (
              <Space
                key={tag}
                className="bg-[#404444] text-white rounded-full text-sm py-0.5 px-2 hover:bg-[#606464]"
              >
                {tag}
              </Space>
            ))}
          </Row>
          <Row className="justify-between">
            <Typography.Text className="text-[#606264] text-sm">
              {artwork.created_at !== null &&
                (preview
                  ? formatToLongNoSecondStyle(new Date().toISOString())
                  : formatToLongNoSecondStyle(artwork.created_at))}
            </Typography.Text>
            {artwork.user_id === user_id && artwork.role === currentRole && (
              <ConfigProvider
                theme={{
                  components: {
                    Dropdown: {
                      paddingBlock: undefined,
                    },
                  },
                  token: {
                    colorBgElevated: "translate",
                    borderRadiusLG: 2,
                    boxShadowSecondary: "none",
                    controlItemBgHover: "translate",
                  },
                }}
              >
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: "1",
                        label: (
                          <Space
                            className="text-[#606264] text-base bg-[#1D1F22] py-0.5 px-1.5"
                            onClick={handlOwnArtworkEdit}
                          >
                            <span>Edit</span>
                          </Space>
                        ),
                      },
                    ],
                  }}
                  placement="bottom"
                >
                  <EllipsisOutlined className="text-white" />
                </Dropdown>
              </ConfigProvider>
            )}
          </Row>
          <Col>
            <hr className="w-full bg-white opacity-10" />
          </Col>
        </Col>
      </Col>
      <Col
        ref={containerRef}
        className="flex flex-1 flex-col h-full pb-3 overflow-auto"
      >
        {artwork.commented_count > 0 ? (
          infiniteData?.list && artwork.commented_count > 0 ? (
            <CommentDetail
              modal={modal}
              setReloadState={setReloadState}
              getCommentCount={getCommentCount}
              commentCount={commentCount}
              artworkUserId={artwork.user_id}
              user_id={user_id}
              commentListData={infiniteData?.list}
              setIsCommentInputVisible={setIsCommentInputVisible}
              setCommentReplyState={setCommentReplyState}
              setReplyCommentInfo={setReplyCommentInfo}
            />
          ) : (
            <Skeleton active paragraph={{ rows: 10 }} />
          )
        ) : (
          <CommentDetail
            modal={modal}
            setReloadState={setReloadState}
            getCommentCount={getCommentCount}
            commentCount={commentCount}
            artworkUserId={artwork.user_id}
            user_id={user_id}
            commentListData={infiniteData?.list}
            setIsCommentInputVisible={setIsCommentInputVisible}
            setCommentReplyState={setCommentReplyState}
            setReplyCommentInfo={setReplyCommentInfo}
          />
        )}
      </Col>
      <div className="absolute bottom-0 left-0 w-full pb-[15px] px-4">
        {alarms.map((alarm) => (
          <PortfolioActionAlarm
            key={alarm.id}
            actionType={alarm.type}
            setBookmarkState={setBookmarkState}
            bookmarkTitle={bookmarkTitle}
          />
        ))}
        {bookmarkState && (
          <BookmarkSetting
            setBookmarkSetting={setBookmarkState}
            artworkId={artwork.id}
            setShowAlarm={showAlarm}
          />
        )}
      </div>
    </Row>
  );

  const renderExpandedIndividualContent = () => (
    <Row className="pt-6 flex flex-col flex-1 h-full overflow-hidden">
      <Col className="flex flex-row gap-4 pr-6 pb-6">
        <Col onClick={() => goToAuthorInfoPage(artwork)}>
          <Avatar
            size={60}
            className="object-cover"
            src={
              getImgUrl(
                preview
                  ? isCompanyRole
                    ? companyInfo?.extended_info?.logo_url
                    : profile?.avatar_url
                  : (artwork.author as IUser)?.avatar_url,
              ) || <DefaultUserIcon />
            }
          />
        </Col>
        {jobPanelVisible && (
          <div className="absolute top-8 right-10 z-50">
            <JobPanel
              setJobPanelVisible={setJobPanelVisible}
              setSelectJob={setSelectJob}
            />
          </div>
        )}
        <Row className="w-full text-base text-[#fff9] flex flex-col flex-1 overflow-hidden pt-1">
          <Col className="text-base font-bold flex flex-row justify-between">
            <Typography.Paragraph
              ellipsis={{ rows: 1 }}
              className="!mb-0 text-[22px] text-white"
            >
              {preview
                ? isCompanyRole
                  ? companyInfo?.extended_info?.shortname
                  : profile?.user_name
                : (artwork.author as IUser)?.user_name}
            </Typography.Paragraph>
            {artwork.user_id !== user_id && artwork.id !== "preview" && (
              <div className="flex flex-row">
                {currentRole === UserType.Company && (
                  <MessageFilled
                    className="text-[#00ADAE] cursor-pointer"
                    onClick={() => setJobPanelVisible(true)}
                  />
                )}
                {isFollowed ? (
                  <Space
                    className="text-[#00ADAE] text-base ml-2 flex gap-1 items-center cursor-pointer"
                    onClick={() => onUnFollowing(artwork.user_id, artwork.role)}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                  >
                    {isHovered ? (
                      <>
                        <CloseOutlined />
                        <span className="text-[#00ADAE] custom-alibaba-font">
                          UnFollow
                        </span>
                      </>
                    ) : (
                      <>
                        <Icon component={FollowingIcon} />
                        <span className="text-[#00ADAE] custom-alibaba-font">
                          Following
                        </span>
                      </>
                    )}
                  </Space>
                ) : (
                  <Space
                    className="text-[#00ADAE] text-base ml-4 flex gap-1 items-center cursor-pointer"
                    onClick={() => onFollowing(artwork.user_id, artwork.role)}
                  >
                    <Icon component={FollowIcon} />
                    <span className="text-[#00ADAE] custom-alibaba-font">
                      Follow
                    </span>
                  </Space>
                )}
              </div>
            )}
          </Col>
          <Col className="text-sm overflow-hidden whitespace-nowrap text-ellipsis opacity-85 text-white">
            {preview
              ? isCompanyRole
                ? companyInfo?.extended_info?.industry
                  ? companyInfo?.extended_info?.scale
                    ? `${companyInfo?.extended_info?.industry} | ${companyInfo?.extended_info?.scale}`
                    : companyInfo?.extended_info?.industry
                  : companyInfo?.extended_info?.scale
                    ? companyInfo?.extended_info?.scale
                    : ""
                : profile?.introduction
              : (artwork.author as IUser)?.introduction}
          </Col>
          {preview
            ? isCompanyRole
              ? companyInfo?.extended_info?.country && (
                  <Col className="text-sm text-[#606264] flex gap-1 items-center">
                    <EnvironmentFilled />
                    <Typography.Text className="text-[#606264] custom-alibaba-font">
                      {companyInfo?.extended_info?.country}
                    </Typography.Text>
                  </Col>
                )
              : (profile?.city || profile?.country) && (
                  <Col className="text-sm text-[#606264] flex gap-1 items-center">
                    <EnvironmentFilled />
                    <Typography.Text className="text-[#606264] custom-alibaba-font">
                      {profile?.city
                        ? profile?.country
                          ? `${profile?.city}, ${profile?.country}`
                          : profile?.city
                        : profile?.country}
                    </Typography.Text>
                  </Col>
                )
            : ((artwork.author as IUser)?.country ||
                (artwork.author as IUser)?.city) && (
                <Col className="text-sm text-[#606264] flex gap-1 items-center">
                  <EnvironmentFilled />
                  <Typography.Text className="text-[#606264] custom-alibaba-font">
                    {(artwork.author as IUser)?.city
                      ? (artwork.author as IUser)?.country
                        ? `${(artwork.author as IUser)?.city}, ${
                            (artwork.author as IUser)?.country
                          }`
                        : (artwork.author as IUser)?.city
                      : (artwork.author as IUser)?.country}
                  </Typography.Text>
                </Col>
              )}
        </Row>
      </Col>
      <Col className="flex flex-col gap-4">
        <Col>
          <Typography.Paragraph
            ellipsis={{ rows: 2 }}
            className="text-white font-bold text-2xl !mb-0"
          >
            {artwork?.title}
          </Typography.Paragraph>
        </Col>
        <Col>
          <Typography.Paragraph
            ellipsis={{ rows: 3 }}
            className="text-white opacity-85 text-base !mb-2"
          >
            {artwork?.description}
          </Typography.Paragraph>
          <Row className="flex gap-2 mb-2">
            {artwork?.tags?.map((tag) => (
              <Space
                key={tag}
                className="bg-[#404444] text-white rounded-full text-sm py-0.5 px-2 hover:bg-[#606464]"
              >
                {tag}
              </Space>
            ))}
          </Row>
          <Row className="justify-between">
            <Typography.Text className="text-[#606264] text-sm">
              {artwork.created_at !== null &&
                (preview
                  ? formatToLongNoSecondStyle(new Date().toISOString())
                  : formatToLongNoSecondStyle(artwork.created_at))}
            </Typography.Text>
            {artwork.user_id === user_id && artwork.role === currentRole && (
              <ConfigProvider
                theme={{
                  components: {
                    Dropdown: {
                      paddingBlock: undefined,
                    },
                  },
                  token: {
                    colorBgElevated: "translate",
                    borderRadiusLG: 2,
                    boxShadowSecondary: "none",
                    controlItemBgHover: "translate",
                  },
                }}
              >
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: "1",
                        label: (
                          <Space
                            className="text-[#606264] text-base bg-[#1D1F22] py-0.5 px-1.5"
                            onClick={handlOwnArtworkEdit}
                          >
                            <span>Edit</span>
                          </Space>
                        ),
                      },
                    ],
                  }}
                  placement="bottom"
                >
                  <EllipsisOutlined className="text-white" />
                </Dropdown>
              </ConfigProvider>
            )}
          </Row>
          <Col>
            <hr className="w-full bg-white opacity-10" />
          </Col>
        </Col>
      </Col>
      <Col
        ref={containerRef}
        className="flex flex-1 flex-col h-full pb-3 overflow-auto"
      >
        {artwork.commented_count > 0 ? (
          infiniteData?.list && artwork.commented_count > 0 ? (
            <CommentDetail
              modal={modal}
              setReloadState={setReloadState}
              getCommentCount={getCommentCount}
              commentCount={commentCount}
              artworkUserId={artwork.user_id}
              user_id={user_id}
              commentListData={infiniteData?.list}
              setIsCommentInputVisible={setIsCommentInputVisible}
              setCommentReplyState={setCommentReplyState}
              setReplyCommentInfo={setReplyCommentInfo}
            />
          ) : (
            <Skeleton active paragraph={{ rows: 10 }} />
          )
        ) : (
          <CommentDetail
            modal={modal}
            setReloadState={setReloadState}
            getCommentCount={getCommentCount}
            commentCount={commentCount}
            artworkUserId={artwork.user_id}
            user_id={user_id}
            commentListData={infiniteData?.list}
            setIsCommentInputVisible={setIsCommentInputVisible}
            setCommentReplyState={setCommentReplyState}
            setReplyCommentInfo={setReplyCommentInfo}
          />
        )}
      </Col>
      <div className="absolute bottom-0 left-0 w-full pb-[15px] px-4">
        {alarms.map((alarm) => (
          <PortfolioActionAlarm
            key={alarm.id}
            actionType={alarm.type}
            setBookmarkState={setBookmarkState}
            bookmarkTitle={bookmarkTitle}
          />
        ))}
        {bookmarkState && (
          <BookmarkSetting
            setBookmarkSetting={setBookmarkState}
            artworkId={artwork.id}
            setShowAlarm={showAlarm}
          />
        )}
      </div>
    </Row>
  );

  return (
    <Col
      flex={`${isCollapsed ? "106px" : "456px"}`}
      className="bg-[#2B2D2F] rounded-xl flex flex-col h-full overflow-hidden relative"
    >
      <Col
        className={`${
          !isCollapsed ? "px-6 py-4" : "px-2 py-2"
        } flex flex-col flex-1 text-[22px] relative w-full`}
      >
        <div className="flex justify-between">
          <div>
            {!preview && (
              <Icon
                onClick={handleCollapseToggle}
                component={isCollapsed ? ExpandIcon : CollapseIcon}
              />
            )}
          </div>
          <Icon onClick={handleNavigateBack} component={CloseIcon} />
        </div>
        {!isCollapsed
          ? artwork.role === UserType.Individual
            ? renderExpandedIndividualContent()
            : renderExpandedCompanyContent()
          : artwork.role === UserType.Individual
            ? renderCollapsedIndividualContent()
            : renderCollapsedCompanyContent()}
      </Col>
      {!isCollapsed &&
        (preview ? (
          <Row className="flex gap-5 justify-center mb-4">
            <Button
              className="py-1.5 px-14 h-full rounded-full bg-transparent border border-[#606264] text-[#606264]"
              onClick={handleCancelClick}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              className="py-1.5 px-14 h-full rounded-full bg-[#00ADAE] text-white border-none"
              onClick={handleSubmitClick}
            >
              Submit
            </Button>
          </Row>
        ) : isCommentInputVisible ? (
          <CommentInput
            modal={modal}
            setReloadState={setReloadState}
            getCommentCount={getCommentCount}
            setCommentInputState={setIsCommentInputVisible}
            artworkId={artwork.id}
            setCommentReplyState={setCommentReplyState}
            commentReplyState={commentReplyState}
            replyCommentInfo={replyCommentInfo}
          />
        ) : (
          <Row className="h-[72px] bg-[#232529] rounded-b-xl py-4 pl-3 pr-6 flex flex-row gap-5">
            <Col className="flex-1">
              <Button
                className="flex p-1.5 rounded-full bg-[#292D31] w-full h-full border-0 hover:!bg-[#292D31]"
                onClick={() => setIsCommentInputVisible(true)}
              >
                <Avatar
                  className="w-[30px] h-[30px] object-cover"
                  size="large"
                  src={
                    getImgUrl(
                      isCompanyRole
                        ? companyInfo?.extended_info?.logo_url
                        : profile?.avatar_url,
                    ) || <DefaultUserIcon width={30} height={30} />
                  }
                />
              </Button>
            </Col>
            <Col className="flex flex-row gap-4 items-center text-[#999]">
              <Col className="flex items-center gap-1 text-[#999]">
                {isLiked ? (
                  <Icon
                    component={ActiveHeartIcon}
                    className="text-xl hover:text-white"
                    onClick={onLikeClick}
                  />
                ) : (
                  <HeartOutlined
                    className="text-xl hover:text-white"
                    onClick={onLikeClick}
                  />
                )}
                <span className="text-base">{artwork.liked_count}</span>
              </Col>
              <Col className="flex items-center gap-1 text-[#999]">
                {isFavourited ? (
                  <Icon
                    component={ActiveFavouriteIcon}
                    className="text-xl hover:text-white"
                    onClick={onFavouriteClick}
                  />
                ) : (
                  <StarOutlined
                    className="text-xl hover:text-white"
                    onClick={onFavouriteClick}
                  />
                )}
                <span className="text-[#999] text-base">
                  {artwork.favorited_count}
                </span>
              </Col>
              <Col className="flex items-center gap-1 text-[#999]">
                <MessageOutlined
                  className="text-xl hover:text-white"
                  onClick={() => setIsCommentInputVisible(true)}
                />
                <span className="text-[#999] text-base">{commentCount}</span>
              </Col>
              <Col className="flex items-center gap-1">
                <ShareAltOutlined
                  className="text-xl hover:text-white"
                  onClick={handleShareClick}
                />
              </Col>
            </Col>
          </Row>
        ))}
    </Col>
  );
};
