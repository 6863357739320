import { isImageFile, isVideoFile } from "@/utils/upload";
import { IResourceComponentsProps, useCustom } from "@refinedev/core";
import { Checkbox, InputNumber, Modal, Radio, Tag } from "antd";
import React, { useState, useRef, useEffect } from "react";
import toast from "react-hot-toast";
import { useConfigProvider } from "../../contexts";

import {
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Create, useForm, useStepsForm } from "@refinedev/antd";
import { useApiUrl } from "@refinedev/core";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Tabs,
  Upload,
  message,
} from "antd";

import {
  softwareOptions,
  unitforPaperworkOptions,
  unitforTimeOptions,
} from "../../utils/selectionList";

import {
  UploadChangeParam,
  UploadFile,
  UploadProps,
} from "antd/lib/upload/interface";
import { useNavigate } from "react-router-dom";

import { PRIVACY_POLICY_URL, USER_AGREEMENT_URL } from "@/constants";
import { modalConfirmSync } from "@/utils/common";
import { datePickerFormat, generateTimezoneOptions } from "@/utils/date";
import { removePrefix } from "@/utils/upload";
import { RcFile } from "antd/lib/upload/interface";
import { IUserProfile } from "../../interfaces";
import styles from "./index.module.css";
import { settleDateOptions, settleDateOptionsForDev } from "./utils";
const isDEV = import.meta.env.DEV;

enum JobPaymentMethod {
  Paperwork = 0,
  Time = 1,
  Negotiable = 2,
  Undefined = 3,
}

type ExtraProps = {
  company_shortname: string;
  is_company_verified: number;
  project_thumbnail_url: string;
  title: string;
};

type ApplicantCardProps = {
  project_name: string;
  location: string;
  created_at: string;
  industry: string;
  introduction: string;
  city: string;
  avatar_url: string;
  user_name: string;
  id: string;
  rejection_reason: string;
  company_shortname: string;
  job_title: string;
  currency: string;

  status: string;

  answer_url: string;
  answer_link: string;

  headcount: number;
  payment_method: string;
  count: number;
  count_unit: string;
  rate: number;
  rate_unit: string;
  total_cost: number;

  // added by combining API results
  extra: ExtraProps;
};

type ModalProps = {
  applicant: ApplicantCardProps;
  open: boolean;
  setOpen: (x: boolean) => void;
  refreshList: () => void;
};
interface FormValues {
  projectRepresentative: string;
  documentType: string;
  documentNumber: string;
}
interface UserSettingResponse {
  email: string;
  timezone: string;
}

const timezoneOptions = generateTimezoneOptions();

export const JobContractModal = ({
  applicant,
  open,
  setOpen,
  refreshList,
}: ModalProps) => {
  const apiUrl = useApiUrl();
  const TOKEN_KEY = "leyline-auth";
  const token = localStorage.getItem(TOKEN_KEY);
  const marketplaceUploadUrl = import.meta.env.VITE_MEDIA_ASSETS_BASE_URL;

  const navigate = useNavigate();

  const { queryResult } = useStepsForm<IUserProfile>();

  const [pending, setPending] = useState(false);
  const [currency, setCurrency] = useState("USD");

  const [paymentMethod, setPaymentMethod] = useState(
    JobPaymentMethod.Paperwork,
  );
  // const [calculatedPrice, setCalculatedPrice] = useState("0.00");

  const [testFileList, setTestFileList] = useState<UploadFile[]>([]);

  const [loading, setLoading] = useState(false);
  const [clicked, setClicked] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [tagsCount, setTagsCount] = useState(0);
  const [tagSelected, setTagSelected] = useState([] as string[]);
  const [form] = Form.useForm();

  const IMG_PREFIX = import.meta.env.VITE_MEDIA_ASSETS_BASE_URL;

  const { data: userSettingData } = useCustom<UserSettingResponse>({
    url: `${apiUrl}/users/settings`,
    method: "get",
    config: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      query: {},
    },
    queryOptions: {
      enabled: open,
    },
  });

  useEffect(() => {
    open && form.setFieldValue("timezone", userSettingData?.data?.timezone);
  }, [open, userSettingData, form]);

  const previewFile = (file: UploadFile, isImage: boolean) => {
    if (!file || !file.originFileObj) return;
    const src = file.url || URL.createObjectURL(file.originFileObj as Blob);
    if (isImage) {
      const imgWindow = window.open(src);
      if (imgWindow) {
        imgWindow.document.write(`<img src="${src}" style="width: 100%;">`);
      }
    } else if (isVideoFile(file)) {
      const videoWindow = window.open(src);
      if (videoWindow) {
        videoWindow.document.write(
          `<video src="${src}" controls style="width: 100%;"></video>`,
        );
      }
    }
  };

  const beforeUpload = (file: RcFile) => {
    // const isImage = file.type === "image/jpeg" || file.type === "image/png";
    // const isPDF = file.type === "application/pdf";
    const isLt10M = file.size / 1024 / 1024 < 10;

    // if (!isImage && !isPDF) {
    //   message.error("You can only upload JPG/PNG image or PDF file!");
    // }
    if (!isLt10M) {
      message.error("File must smaller than 10MB!");
      return Upload.LIST_IGNORE;
    }

    return isLt10M;
  };

  const testUploadProps: UploadProps = {
    action: `${apiUrl}/users/upload`,
    headers: {
      // biome-ignore lint:
      authorization: "Bearer " + localStorage.getItem("leyline-auth"),
    },
    accept:
      "image/*,.pdf,.xls,.xlsx,.csv,.txt,.pptx,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    name: "file",
    multiple: false,
    fileList: testFileList,
    listType: "picture",
    maxCount: 5,
    beforeUpload: beforeUpload,
    onChange: ({ fileList }) => {
      const newFileList = fileList
        .filter((file) => {
          // const isImage = file.type === "image/jpeg" || file.type === "image/png";
          // const isPDF = file.type === "application/pdf";
          const isLt10M = file.size || 0 / 1024 / 1024 < 10;

          // if (!isImage && !isPDF) {
          //   return false;
          // }
          if (!isLt10M) {
            return false;
          }
          return true;
        })
        .map((file) => {
          return {
            uid: file.uid,
            name: file.name,
            status: file.status,
            url: file.url || IMG_PREFIX + file.response?.id,
            type: file.type,
          };
        });
      setTestFileList(newFileList);
      newFileList.length === 0 && form.setFieldsValue({ test: undefined });
    },
    onPreview: async (file) => {
      previewFile(file, isImageFile(file));
    },
  };

  const { mode, setMode } = useConfigProvider();

  // /notfit-reasons
  // API integration - TODO
  const unmatchReasons = [
    "Fee mismatch",
    "Industry mismatch",
    "Skill mismatch",
    "Software mismatch",
    "Work model mismatch",
    "Job type mismatch",
    "Information not accurate",
  ];

  const selectAfter = (
    <Select value={currency} onChange={setCurrency} style={{ width: 80 }}>
      <Select.Option value="USD">USD</Select.Option>
      <Select.Option value="CNY">CNY</Select.Option>
    </Select>
  );

  const tagClasses = "hover: cursor-pointer mt-1 mb-1";
  // @ts-ignore
  const ReasonTag = ({ children, clicked, setClicked }) => {
    return clicked ? (
      <Tag
        color="#2db7f5"
        className={tagClasses}
        onClick={() => {
          setClicked(false);
        }}
      >
        {children}
      </Tag>
    ) : (
      <Tag
        className={tagClasses}
        onClick={() => {
          setClicked(true);
        }}
      >
        {children}
      </Tag>
    );
  };

  const warn = (text: string) => {
    if (mode === "light") {
      toast(text, {
        icon: "⚠️",
      });
    } else {
      toast(text, {
        icon: "⚠️",
        style: {
          background: "#333",
          color: "#fff",
        },
      });
    }
  };

  // @ts-ignore
  const tags = [];
  unmatchReasons.forEach((reason, idx) => {
    tags.push(
      // @ts-ignore
      // biome-ignore lint:
      <React.Fragment key={idx}>
        <ReasonTag
          clicked={clicked[idx]} // @ts-ignore
          setClicked={(val) => {
            setClicked(
              clicked.map((c, i) => {
                if (i === idx) {
                  return val;
                }
                return c;
              }),
            );
            if (val) {
              setTagsCount(tagsCount + 1);
              setTagSelected((current) => {
                return [...current, reason];
              });
            } else {
              setTagsCount(tagsCount - 1);
              setTagSelected((current) => {
                return current.filter((v) => v !== reason);
              });
            }
          }}
        >
          {reason}
        </ReasonTag>
      </React.Fragment>,
    );
  });

  const onFinish = async () => {
    try {
      await form.validateFields();
      setLoading(true);

      const timezone = form.getFieldValue("timezone");
      const headcount = form.getFieldValue("numberOfPeople");
      const quantity = form.getFieldValue("budget")?.quantity;
      const duration_per_ppl = form.getFieldValue("budget")?.duration_per_ppl;
      const unit = form.getFieldValue("budget")?.unit;
      const unit_price = form.getFieldValue("budget")?.unit_price;

      const totalAmount = form.getFieldValue("totalAmount");
      // const depositAmount = form.getFieldValue("depositAmount");

      // const startDate = form.getFieldValue("start_date");
      // const endDate = form.getFieldValue("end_date");
      const workDateRange = form.getFieldValue("workDateRange");
      const settle_day = form.getFieldValue("settle_day");
      const totalAmountEx = form.getFieldValue("totalAmountEx");

      // sanity check
      if (!/^\+?([1-9]\d*)$/.test(headcount)) {
        toast("Headcount can only fill in positive integers", {
          icon: "⚠️",
        });
        return;
      }
      if (parseInt(headcount) >= 999999) {
        toast("Headcount should be less than 999999", {
          icon: "⚠️",
        });
        return;
      }

      if (
        paymentMethod === JobPaymentMethod.Paperwork ||
        paymentMethod === JobPaymentMethod.Time
      ) {
        if (!/^\+?([1-9]\d*)(.\d{1,2})?$/.test(unit_price)) {
          toast(
            "Unit price can only fill in positive numbers with up to two decimal places",
            {
              icon: "⚠️",
            },
          );
          return;
        }
        if (parseFloat(unit_price) >= 999999999) {
          toast("Unit price should be less than 999999999", {
            icon: "⚠️",
          });
          return;
        }
      }

      if (paymentMethod === JobPaymentMethod.Paperwork) {
        if (!/^\+?([1-9]\d*)(.\d{1,2})?$/.test(quantity)) {
          toast(
            "Quantity can only fill in positive numbers with up to two decimal place",
            {
              icon: "⚠️",
            },
          );
          return;
        }
        if (parseFloat(quantity) >= 999999) {
          toast("Quantity should be less than 999999", {
            icon: "⚠️",
          });
          return;
        }
      }

      if (paymentMethod === JobPaymentMethod.Time) {
        if (!/^\+?([1-9]\d*)(.\d{1,2})?$/.test(duration_per_ppl)) {
          toast(
            "Duration per person can only fill in positive numbers with up to two decimal places",
            {
              icon: "⚠️",
            },
          );
          return;
        }
        if (parseFloat(duration_per_ppl) >= 999999) {
          toast("Duration should be less than 999999", {
            icon: "⚠️",
          });
          return;
        }
      }

      // if (!/^\+?([1-9]\d*)(.\d{1,2})?$/.test(depositAmount)) {
      //   toast(
      //     "Deposit amount can only fill in positive numbers with up to two decimal places",
      //     {
      //       icon: "⚠️",
      //     },
      //   );
      //   return;
      // }
      // if (parseFloat(depositAmount) >= 999999999) {
      //   toast("Deposit amount should be less than 999999999", {
      //     icon: "⚠️",
      //   });
      //   return;
      // }

      if (!testFileList.length) {
        toast("At least one contract file must be uploaded.", {
          icon: "⚠️",
        });
        return;
      }

      // if (!startDate) {
      //   toast("Work Start Date should be filled", {
      //     icon: "⚠️",
      //   });
      //   return;
      // }
      // if (!endDate) {
      //   toast("Work End Date should be filled", {
      //     icon: "⚠️",
      //   });
      //   return;
      // }
      // if (startDate.isAfter(endDate)) {
      //   toast("Work Start Date should be on or before Work End Date", {
      //     icon: "⚠️",
      //   });
      //   return;
      // }

      // CreateContractIn
      const requestBody = {
        start_date: workDateRange[0].format("YYYY-MM-DD HH:mm:ss"),
        end_date: workDateRange[1].format("YYYY-MM-DD HH:mm:ss"),
        headcount: headcount,
        timezone,
        payment_method:
          paymentMethod === JobPaymentMethod.Paperwork
            ? "Piece-rate"
            : paymentMethod === JobPaymentMethod.Time
              ? "Time-based"
              : paymentMethod === JobPaymentMethod.Undefined
                ? null
                : "Negotiable",
        count:
          paymentMethod !== JobPaymentMethod.Negotiable &&
          paymentMethod !== JobPaymentMethod.Undefined
            ? paymentMethod === JobPaymentMethod.Paperwork
              ? parseInt(quantity)
              : parseFloat(duration_per_ppl)
            : null,
        count_unit:
          paymentMethod !== JobPaymentMethod.Negotiable &&
          paymentMethod !== JobPaymentMethod.Undefined
            ? unit
            : null,
        rate:
          paymentMethod !== JobPaymentMethod.Negotiable &&
          paymentMethod !== JobPaymentMethod.Undefined
            ? parseFloat(unit_price)
            : null,
        rate_unit:
          paymentMethod === JobPaymentMethod.Time ? calculateRateUnit : "",
        total_cost:
          paymentMethod === JobPaymentMethod.Negotiable
            ? parseFloat(totalAmount)
            : parseFloat(totalAmountEx),
        // deposit: parseFloat(depositAmount),
        settle_day,
        contract_file_urls:
          testFileList.length > 0
            ? testFileList.filter((x) => !!x).map((x) => removePrefix(x.url))
            : null,
        currency,
      };
      console.log("Data to send: ", requestBody);
      // const operateRes = await modalConfirmSync({
      //   title: "Signing Notice",
      //   content:
      //     "To protect your rights, please contact customer service before signing to understand the processes for company payments, receiving invoices, etc. Contact information: leyline@leylinepro.com",
      //   cancelText: "Cancel",
      //   okText: "Confirmed Contacted",
      // });
      // if (!operateRes) return;
      const response = await fetch(
        `${apiUrl}/applications/company/${applicant.id}/sign`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestBody),
        },
      );
      console.log("response: ", response);
      if (!response.ok) {
        throw new Error("Failed to submit contract");
      }

      message.success(
        "Your contract has been submitted and is waiting for processing. Redirecting back...",
      );
      setOpen(false);
      refreshList();

      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    } catch (error: any) {
      const errorPathName = error?.errorFields?.[0]?.name;
      console.error("Error:", error);
      if (errorPathName)
        form.scrollToField(errorPathName, {
          block: "center",
        });
      else message.error("Failed to submit contract");
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (!open) {
      setTestFileList([]);
      return form.resetFields();
    }
    let payMethod: null | number = null;
    form.setFieldsValue({
      numberOfPeople: applicant.headcount,
      budget: {
        unit: applicant.count_unit,
        unit_price: applicant.rate,
      },
      totalAmount: applicant.total_cost,
    });
    setCurrency(applicant.currency);

    if (applicant.payment_method === "Piece-rate") {
      payMethod = JobPaymentMethod.Paperwork;
      setPaymentMethod(payMethod);
      form.setFieldsValue({
        budget: {
          quantity: applicant.count,
        },
      });
    }
    if (applicant.payment_method === "Time-based") {
      payMethod = JobPaymentMethod.Time;
      setPaymentMethod(payMethod);
      setCalculateRateUnit(`/person/${applicant.count_unit}`);
      form.setFieldsValue({
        budget: {
          duration_per_ppl: applicant.count,
        },
      });
    }
    if (applicant.payment_method === "Negotiable") {
      payMethod = JobPaymentMethod.Negotiable;
      setPaymentMethod(payMethod);
    }
    console.log("applicant info", applicant, form.getFieldsValue());
    setTimeout(() => {
      recalculatePrice({ method: payMethod as number });
    }, 50);
  }, [applicant, form, open]);

  const [calculateRateUnit, setCalculateRateUnit] = useState("");

  // @ts-ignore
  const recalculatePrice = (
    arg: { method: JobPaymentMethod } | null | number,
  ) => {
    // @ts-ignore
    const { method } = arg || {};
    const headcount = form.getFieldValue("numberOfPeople");
    const quantity = form.getFieldValue("budget")?.quantity;
    const duration_per_ppl = form.getFieldValue("budget")?.duration_per_ppl;
    const unit = form.getFieldValue("budget")?.unit;
    const unit_price = form.getFieldValue("budget")?.unit_price;

    console.log(
      headcount,
      quantity,
      duration_per_ppl,
      unit,
      unit_price,
      method,
      paymentMethod,
    );

    if (method !== undefined) {
      if (method === JobPaymentMethod.Paperwork) {
        // form.setFieldValue(["budget", "unit"], "");
        setCalculateRateUnit("");
        if (quantity && unit_price) {
          const price = parseInt(quantity) * parseFloat(unit_price);
          // setCalculatedPrice(price.toFixed(2));
          form.setFieldValue("totalAmountEx", price.toFixed(2));
        } else {
          form.setFieldValue("totalAmountEx", "0.00");
        }
      } else if (method === JobPaymentMethod.Time) {
        // form.setFieldValue(["budget", "unit"], "");
        if (unit) {
          setCalculateRateUnit(`/person/${unit}`);
        } else {
          setCalculateRateUnit("");
        }
        if (headcount && duration_per_ppl && unit_price) {
          const price =
            parseInt(headcount) *
            parseFloat(duration_per_ppl) *
            parseFloat(unit_price);
          // setCalculatedPrice(price.toFixed(2));
          form.setFieldValue("totalAmountEx", price.toFixed(2));
        } else {
          // setCalculatedPrice("0.00");
          form.setFieldValue("totalAmountEx", "0.00");
        }
      } else {
        setCalculateRateUnit("");
      }
    } else {
      if (paymentMethod === JobPaymentMethod.Paperwork) {
        setCalculateRateUnit("");
        if (quantity && unit_price) {
          const price = parseInt(quantity) * parseFloat(unit_price);
          // setCalculatedPrice(price.toFixed(2));
          form.setFieldValue("totalAmountEx", price.toFixed(2));
        } else {
          // setCalculatedPrice("0.00");
          form.setFieldValue("totalAmountEx", "0.00");
        }
      } else if (paymentMethod === JobPaymentMethod.Time) {
        if (unit) {
          setCalculateRateUnit(`/person/${unit}`);
        } else {
          setCalculateRateUnit("");
        }
        if (headcount && duration_per_ppl && unit_price) {
          const price =
            parseInt(headcount) *
            parseFloat(duration_per_ppl) *
            parseFloat(unit_price);
          // setCalculatedPrice(price.toFixed(2));
          form.setFieldValue("totalAmountEx", price.toFixed(2));
        } else {
          // setCalculatedPrice("0.00");
          form.setFieldValue("totalAmountEx", "0.00");
        }
      } else {
        setCalculateRateUnit("");
      }
    }
  };

  return (
    <>
      <Modal
        title="Contract now"
        open={open}
        onOk={onFinish}
        onCancel={() => {
          setOpen(false);
        }}
        width={600}
        className={styles.contractModal}
        destroyOnClose
      >
        {open && (
          <>
            <Row justify="center" className="mt-12">
              <Col span={20}>
                <Form
                  form={form}
                  name={`contractJobForm-${applicant.id}`}
                  // onFinish={onFinish}
                  layout="horizontal"
                  scrollToFirstError
                >
                  <Row gutter={20} className="mb-4">
                    <Col span={24}>
                      Project Name: <b>{applicant.project_name}</b>
                      <br />
                      Job Name:{" "}
                      <b>{applicant?.job_title || applicant?.extra?.title}</b>
                      <br />
                      Party A:{" "}
                      <b>
                        {applicant?.company_shortname ||
                          applicant?.extra?.company_shortname}
                      </b>
                      <br />
                      Party B: <b>{applicant.user_name}</b>
                      <br />
                    </Col>
                  </Row>

                  <Form.Item
                    label="Timezone"
                    name="timezone"
                    rules={[
                      {
                        required: true,
                        message: "Please select the timezone",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        option?.children
                          ? (option.children as unknown as string)
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          : false
                      }
                    >
                      {timezoneOptions.map((option) => (
                        <Select.Option key={option.value} value={option.value}>
                          {option.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Please enter the work start date",
                      },
                    ]}
                    label="Work Time Period"
                    name="workDateRange"
                  >
                    <DatePicker.RangePicker
                      showTime
                      format={"MMM DD, YYYY HH:mm:ss"}
                    />
                  </Form.Item>
                  {/* <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Please enter the work start date",
                      },
                    ]}
                    label="Work Start Date"
                    name={"start_date"}
                  >
                    <DatePicker format={datePickerFormat} />
                  </Form.Item>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Please enter the work end date",
                      },
                    ]}
                    label="Work End Date"
                    name={"end_date"}
                  >
                    <DatePicker format={datePickerFormat} />
                  </Form.Item> */}

                  <Row gutter={20}>
                    <Col span={24}>
                      <Form.Item
                        label="Headcount"
                        name="numberOfPeople"
                        rules={[
                          {
                            required: true,
                            message: "Please enter the headcount",
                          },
                        ]}
                      >
                        <InputNumber
                          style={{ width: "100%" }}
                          min={1}
                          max={99999 - 1}
                          precision={0}
                          onChange={(v) => {
                            // @ts-ignore
                            recalculatePrice(v);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={20}>
                    <Col span={24}>
                      <Form.Item label="Payment Method">
                        <Radio.Group
                          options={[
                            // {
                            //   label: "Piece-rate",
                            //   value: JobPaymentMethod.Paperwork,
                            // },
                            {
                              label: "Time-based",
                              value: JobPaymentMethod.Time,
                            },
                            {
                              label: "Negotiable",
                              value: JobPaymentMethod.Negotiable,
                            },
                          ]}
                          onChange={({ target: { value } }) => {
                            setPaymentMethod(value);
                            recalculatePrice({
                              method: value,
                            });
                            form.setFieldValue(["budget", "unit"], "");
                          }}
                          value={paymentMethod}
                          optionType="button"
                          buttonStyle="solid"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  {paymentMethod !== JobPaymentMethod.Negotiable &&
                  paymentMethod !== JobPaymentMethod.Undefined ? (
                    <>
                      <Row gutter={16}>
                        <Col className="gutter-row" span={12}>
                          {paymentMethod === JobPaymentMethod.Paperwork ? (
                            <Form.Item
                              label="Quantity (Total)"
                              name={["budget", "quantity"]}
                              rules={[{ required: true }]}
                            >
                              <InputNumber
                                style={{ width: "100%" }}
                                min={0.01}
                                max={999999 - 0.01}
                                precision={2}
                                onChange={(v) => {
                                  // @ts-ignore
                                  recalculatePrice(v);
                                }}
                              />
                            </Form.Item>
                          ) : (
                            <Form.Item
                              label="Duration (Per Person)"
                              name={["budget", "duration_per_ppl"]}
                              rules={[{ required: true }]}
                            >
                              <InputNumber
                                style={{ width: "100%" }}
                                min={0.01}
                                max={999999 - 0.01}
                                precision={2}
                                onChange={(v) => {
                                  // @ts-ignore
                                  recalculatePrice(v);
                                }}
                              />
                            </Form.Item>
                          )}
                        </Col>
                        <Col className="gutter-row" span={12}>
                          <Form.Item
                            label="Unit"
                            name={["budget", "unit"]}
                            rules={[{ required: true }]}
                          >
                            <Select
                              style={{ width: "100%" }}
                              placeholder="Select unit"
                              options={
                                paymentMethod === JobPaymentMethod.Paperwork
                                  ? unitforPaperworkOptions
                                  : unitforTimeOptions
                              }
                              onChange={(v) => {
                                // @ts-ignore
                                recalculatePrice(v);
                              }}
                            >
                              {/* Options  */}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Form.Item
                        label="Unit Price"
                        name={["budget", "unit_price"]}
                        rules={[{ required: true }]}
                      >
                        <InputNumber
                          style={{ width: "100%" }}
                          min={0.01}
                          max={999999999 - 0.01}
                          precision={2}
                          addonAfter={selectAfter}
                          onChange={(v) => {
                            // @ts-ignore
                            recalculatePrice(v);
                          }}
                        />
                      </Form.Item>
                    </>
                  ) : (
                    <></>
                  )}

                  <Row gutter={20}>
                    <Col span={24}>
                      {paymentMethod === JobPaymentMethod.Negotiable ? (
                        <Form.Item
                          label="Total"
                          name="totalAmount"
                          rules={[
                            {
                              required: true,
                              message: "Please enter the total amount",
                            },
                          ]}
                        >
                          <InputNumber
                            min={0}
                            precision={2}
                            addonAfter={selectAfter}
                          />
                        </Form.Item>
                      ) : (
                        <Form.Item
                          label="Total"
                          name="totalAmountEx"
                          rules={[
                            {
                              required: false,
                              message: "Please enter the total amount",
                            },
                          ]}
                        >
                          <Input disabled />
                        </Form.Item>
                      )}
                    </Col>
                  </Row>
                  {/* <Row gutter={20}>
                <Col span={24}>
                  <Form.Item
                    label="Deposit"
                    name="depositAmount"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the deposit amount",
                      },
                    ]}
                  >
                    <InputNumber min={0} precision={2} />
                  </Form.Item>
                </Col>
              </Row> */}
                  <Row gutter={20}>
                    <Col span={18}>
                      <Form.Item
                        label="Settlement Date"
                        name="settle_day"
                        rules={[
                          {
                            required: true,
                            message: "Please select settlement date",
                          },
                        ]}
                      >
                        <Select
                          options={
                            isDEV ? settleDateOptionsForDev : settleDateOptions
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <span className=" leading-7">/ Monthly</span>
                    </Col>
                  </Row>
                  <Row gutter={20}>
                    <Col span={24}>
                      <Form.Item
                        label="Contract File"
                        name="test"
                        rules={[
                          {
                            required: true,
                            message: "Please upload the contract file",
                          },
                        ]}
                        extra={
                          <div className=" text-gray-400">
                            <p className="mb-0">
                              1、Please upload the documents that need
                              electronic signature, such as offer or
                              confidentiality agreement.
                            </p>
                            <p className="mb-0">
                              2、The number of files should not exceed 5, and
                              each file should not exceed 10MB in size.
                            </p>
                          </div>
                        }
                      >
                        <Upload {...testUploadProps}>
                          {pending ? (
                            <Button disabled icon={<UploadOutlined />}>
                              Click to Upload
                            </Button>
                          ) : (
                            <Button icon={<UploadOutlined />}>
                              Click to Upload
                            </Button>
                          )}
                        </Upload>
                      </Form.Item>
                      <div className="bg-orange-100 text-center text-orange-500 py-3">
                        <div className="mb-2">
                          Both parties can revoke the contract within 72 hours
                          after signing. Revocation is not permitted after 72
                          hours.
                        </div>
                        <div>
                          Please read carefully and confirm the above contract
                          content.
                        </div>
                      </div>
                      <Form.Item
                        label={""}
                        name="agreement"
                        valuePropName="checked"
                        rules={[
                          {
                            validator: (_, value) =>
                              value
                                ? Promise.resolve()
                                : Promise.reject(
                                    new Error(
                                      "Please read and agree to the User Service Agreement first.",
                                    ),
                                  ),
                          },
                        ]}
                      >
                        <Checkbox
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          I agree to{" "}
                          <a
                            className="!text-[#1677ff] hover:underline"
                            href={USER_AGREEMENT_URL}
                            target="_blank"
                            rel="noreferrer"
                          >
                            User Agreement
                          </a>{" "}
                          and{" "}
                          <a
                            className="!text-[#1677ff] hover:underline"
                            href={PRIVACY_POLICY_URL}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Privacy Policy
                          </a>
                          .
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </>
        )}
      </Modal>
    </>
  );
};
