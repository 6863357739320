import {
  AuditOutlined,
  FolderOpenFilled,
  LayoutFilled,
  LockOutlined,
  LogoutOutlined,
  MailOutlined,
  ProjectFilled,
  SettingOutlined,
  UserSwitchOutlined,
  WalletOutlined,
} from "@ant-design/icons";
import type { GetProp, MenuProps } from "antd";
import { PiBuildingsFill } from "react-icons/pi";

type MenuItem = GetProp<MenuProps, "items">[number];

export const openKeys = ["/custom"];

const menuData: MenuItem[] = [
  {
    key: "/users/home",
    icon: <MailOutlined />,
    label: "Application",
  },
  {
    key: "/portfolio/home",
    icon: <FolderOpenFilled />,
    label: "Portfolio",
  },
  {
    key: "/custom",
    icon: <PiBuildingsFill />,
    label: "My Company",
    children: [
      { key: "/companies/show", label: "Company Information" },
      { key: "/companies", label: "Homepage" },
    ],
  },
  {
    key: "/projects",
    icon: <ProjectFilled />,
    label: "My Projects",
  },
  {
    key: "/jobs",
    icon: <LayoutFilled />,
    label: "My Openings",
  },
  {
    key: "/wallet/settlements",
    icon: <WalletOutlined />,
    label: "Wallet",
  },
  {
    key: "/setting",
    icon: <SettingOutlined />,
    label: "Settings",
  },
];

export const renderFooterMenu = (isCompanyRole: boolean): MenuItem[] => {
  return [
    {
      type: "divider",
    },
    {
      key: "switchRole",
      icon: <UserSwitchOutlined />,
      label: `Switch to ${isCompanyRole ? "Personal" : "Company"} Role`,
    },
    {
      key: "userAgreement",
      icon: <AuditOutlined />,
      label: "User Agreement",
    },
    {
      key: "privacyPolicy",
      icon: <LockOutlined />,
      label: "Privacy Policy",
    },
    {
      key: "/logout",
      icon: <LogoutOutlined />,
      label: "Logout",
    },
  ];
};

export default menuData;
