import JobDetail from "@/pages/enterprises/components/JobDetail";
import JobItemCard from "@/pages/enterprises/components/JobItemCard";
import { getPublicJobs } from "@/pages/enterprises/services";
import { httpGet } from "@/utils/http";
import { IResourceComponentsProps, useApiUrl } from "@refinedev/core";
import { useDebounce, useInfiniteScroll, useRequest } from "ahooks";
import { Col, Empty, Row, Spin } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import PublicJobsHeader from "./components/PublicJobsHeader";
import PublicJobsSearch from "./components/PublicJobsSearch";

interface JobItem {
  company_name: string;
  id: string;
  title: string;
  project_thumbnail_url: string;
  project_name: string;
  project_id: string;
  company_id: string;
  company_shortname: string;
  company_logo_url: string;
  department_name: string;
  location: string;
  remote_option: string;
  employment_type: string;
  created_at: string;
  is_company_verified: number | boolean;
  is_project_verified: number | boolean;
  is_enabled: number;
  is_recommended: number;
  user_id: string;
}

const PAGE_SIZE = 10;
// cache data
let cacheDataList: JobItem[] = [];
let cacheSelectedJob: JobItem | null = null;
let cacheReqParams: {
  searchKeyword: string;
  isVerified: boolean;
  selectedWorkMode: string[];
  selectedEmploymentType: string[];
} = {
  searchKeyword: "",
  isVerified: false,
  selectedWorkMode: [],
  selectedEmploymentType: [],
};

export const PublicJobsAlternative: React.FC<IResourceComponentsProps> = () => {
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [selectedWorkMode, setSelectedWorkMode] = useState<string[]>([]);
  const [selectedEmploymentType, setSelectedEmploymentType] = useState<
    string[]
  >([]);
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  const [jobs, setJobs] = useState<JobItem[]>([]);
  const [selectedJob, setSelectedJob] = useState<JobItem | null>(null);
  const debouncedKeyword = useDebounce(searchKeyword, { wait: 500 });

  const fetchItems = useCallback(
    async (page: number) => {
      const res = await getPublicJobs({
        page,
        size: cacheSelectedJob ? cacheDataList.length : PAGE_SIZE,
        employment_types: selectedEmploymentType,
        remote_options: selectedWorkMode,
        name: debouncedKeyword,
        is_verified: isVerified,
      });
      return {
        total: res.data.total,
        list: res.data.items,
        nextPage:
          res.data.page < res.data.pages
            ? (cacheSelectedJob
                ? cacheDataList.length / PAGE_SIZE
                : res.data.page) + 1
            : undefined,
      };
    },
    [debouncedKeyword, selectedWorkMode, selectedEmploymentType, isVerified],
  );

  const {
    data: allJobsData,
    loading,
    loadingMore,
    noMore,
  } = useInfiniteScroll((d) => (d ? fetchItems(d?.nextPage) : fetchItems(1)), {
    target: ref,
    isNoMore: (d) => !d?.nextPage,
    manual: !!cacheSelectedJob,
    reloadDeps: [
      debouncedKeyword,
      selectedWorkMode,
      selectedEmploymentType,
      isVerified,
    ],
    onSuccess() {
      cacheReqParams = {
        selectedEmploymentType,
        selectedWorkMode,
        searchKeyword,
        isVerified,
      };
    },
  });

  useEffect(() => {
    setJobs(cacheDataList);
    setSelectedJob(cacheSelectedJob);
    setIsVerified(cacheReqParams.isVerified);
    setSearchKeyword(cacheReqParams.searchKeyword);
    setSelectedWorkMode(cacheReqParams.selectedWorkMode);
    setSelectedEmploymentType(cacheReqParams.selectedEmploymentType);
  }, []);

  useEffect(() => {
    // @ts-ignore
    setJobs(allJobsData?.list ?? cacheDataList);
    if (allJobsData?.list) cacheDataList = allJobsData.list;
  }, [allJobsData]);

  return (
    <div className="h-full relative">
      <div
        ref={ref}
        className={`h-full min-h-[300px] overflow-auto z-[9] ${
          selectedJob?.id ? "opacity-0" : ""
        }`}
      >
        <PublicJobsHeader />
        <div
          className="w-4/5 mx-auto -mt-20 mb-5 relative z-10 p-10"
          style={{
            // height: "calc(100% - 30vh + 40px)",
            borderRadius: "8px",
            border: "1px solid rgba(255, 255, 255, 0.2)",
            backgroundColor: "rgba(51, 53, 56, 0.50)",
            backdropFilter: "blur(10px)",
          }}
        >
          <PublicJobsSearch
            searchKeyword={searchKeyword}
            setSearchKeyword={setSearchKeyword}
            selectedWorkMode={selectedWorkMode}
            setSelectedWorkMode={setSelectedWorkMode}
            selectedEmploymentType={selectedEmploymentType}
            setSelectedEmploymentType={setSelectedEmploymentType}
            isVerified={isVerified}
            setIsVerified={setIsVerified}
          />
          <div className="relative shrink-0 flex-1">
            <Row gutter={[20, 20]}>
              {jobs.length > 0 ? (
                jobs.map((job) => (
                  <Col span={8} key={job.id}>
                    <JobItemCard
                      mode="list"
                      job={job}
                      onSelect={(item) => {
                        setSelectedJob(item);
                        cacheSelectedJob = item;
                      }}
                    />
                  </Col>
                ))
              ) : (
                <Empty className="mt-16 mx-auto" />
              )}
            </Row>
          </div>
          <div className="text-center mt-2">
            {loading && <Spin />}
            {loadingMore && <Spin tip="Loading more..." />}
            {noMore && <div className="text-white/[.4]">No more data</div>}
          </div>
        </div>
      </div>
      <div
        className={`h-full flex p-6 ${
          selectedJob ? "absolute w-full left-0 top-0 z-10" : "hidden"
        }`}
      >
        <div className="w-[360px] mr-6 h-full overflow-auto">
          {jobs.map((job) => (
            <JobItemCard
              key={job.id}
              mode="detail"
              job={job}
              isSelected={job.id === selectedJob?.id}
              onSelect={(item) => {
                setSelectedJob(item);
                cacheSelectedJob = item;
              }}
            />
          ))}
        </div>
        {selectedJob?.id && (
          <JobDetail
            id={selectedJob.id}
            onClose={() => {
              setSelectedJob(null);
              cacheSelectedJob = null;
            }}
          />
        )}
      </div>
    </div>
  );
};
