import { ICompanyInfo } from "@/pages/companies/type";
import { getImgUrl, preventAutoUpload } from "@/utils/common";
import { formatToMiddleStyle } from "@/utils/date";
import { axiosInstance } from "@/utils/http";
import { isImageFiles, videoExtensions } from "@/utils/identifyFiles";
import { skillOptions } from "@/utils/selectionList";
import { companySizeOptions, industryOptions } from "@/utils/selectionList";
import { isFileSizeLargerThan, isImageFile, isVideoFile } from "@/utils/upload";
import {
  DownloadOutlined,
  InboxOutlined,
  LoadingOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext, PointerSensor, useSensor } from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Create } from "@refinedev/antd";
import { useApiUrl } from "@refinedev/core";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  FormInstance,
  GetProp,
  Image,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Typography,
  Upload,
  UploadProps,
  message,
} from "antd";
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
  UploadFileStatus,
} from "antd/lib/upload/interface";
import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";

const { Option, OptGroup } = Select;
const { TextArea } = Input;

interface FormValues {
  companyAbbreviation?: string;
  industry?: string;
  companySize?: string;
  companyDescription?: string;
  companyAddresses?: { address: string }[];
  businessField?: string[];
}
type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];
const getBase64 = (file: FileType): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
const videoExtensionsRegex = videoExtensions.join("|");
interface DraggableUploadListItemProps {
  originNode: React.ReactElement<
    // biome-ignore lint:
    any,
    // biome-ignore lint:
    string | React.JSXElementConstructor<any>
  >;
  // biome-ignore lint:
  file: UploadFile<any>;
}

const DraggableUploadListItem = ({
  originNode,
  file,
}: DraggableUploadListItemProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: file.uid,
  });

  const style: React.CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition,
    cursor: "move",
    width: "100px",
    height: "100px",
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      // prevent preview event when drag end
      className={isDragging ? "is-dragging" : ""}
      {...attributes}
      {...listeners}
    >
      {/* hide error tooltip when dragging */}
      {file.status === "error" && isDragging
        ? originNode.props.children
        : originNode}
    </div>
  );
};

export const EditCompanyHomepage: React.FC = () => {
  const apiUrl = useApiUrl();
  const TOKEN_KEY = "leyline-auth";
  const marketplaceUploadUrl = import.meta.env.VITE_MEDIA_ASSETS_BASE_URL;

  const navigate = useNavigate();

  const MAX_BUSINESS_FIELDS = 20;
  const [companyLogoLoading, setCompanyLogoLoading] = useState(false);
  const [companyLogoImageUrl, setCompanyLogoImageUrl] = useState<string | null>(
    null,
  );
  const [fullCompanyLogoImageUrl, setFullCompanyLogoImageUrl] = useState<
    string | null
  >(null);

  const [selectedBusinessField, setSelectedBusinessField] = useState<string[]>(
    [],
  );

  const formRef = useRef<FormInstance>(null);
  const [companyInfo, setCompanyInfo] = useState<ICompanyInfo | null>(null);

  useEffect(() => {
    const fetchCompanyInfo = async () => {
      try {
        const response = await axiosInstance.get("/companies/my-company");
        setCompanyInfo(response.data);
      } catch (error) {
        console.error("Failed to fetch company info", error);
      }
    };
    fetchCompanyInfo();
  }, []);

  useEffect(() => {
    if (companyInfo?.extended_info) {
      const { extended_info } = companyInfo;
      formRef.current?.setFieldsValue({
        companyName: extended_info.name,
        companyAbbreviation: extended_info.shortname,
        establishmentDate: extended_info.established_at
          ? formatToMiddleStyle(extended_info.established_at)
          : "",
        companyLogo: extended_info.logo_url,
        industry: extended_info.industry,
        companySize: extended_info.scale,
        companyDescription: extended_info.description,
        companyAddresses: extended_info.addresses
          ? extended_info.addresses.map((address) => ({ address }))
          : [],
        businessField: extended_info.business_fields,
      });

      if (extended_info.logo_url) {
        const logoUrl = `${marketplaceUploadUrl}${companyInfo.extended_info.logo_url}`;
        setCompanyLogoImageUrl(companyInfo.extended_info.logo_url);
        setFullCompanyLogoImageUrl(logoUrl);
      }

      if (extended_info.business_fields) {
        setSelectedBusinessField(extended_info.business_fields);
      }

      setImgFileList(
        extended_info.pic_urls?.map((url) => ({
          uid: url,
          name: url.substring(url.lastIndexOf("/") + 1),
          status: "done",
          url: getImgUrl(url) || undefined,
        })) || [],
      );
    }
  }, [companyInfo]);

  const handleBusinessFieldChange = (selectedItems: string[]) => {
    if (selectedItems.length > MAX_BUSINESS_FIELDS) {
      message.error(
        `Please select no more than ${MAX_BUSINESS_FIELDS} business fields.`,
      );
      return;
    }
    setSelectedBusinessField(selectedItems);
    formRef.current?.setFieldsValue({ businessField: selectedItems });
  };

  const handleCompanyLogoImageChange = async (
    info: UploadChangeParam<UploadFile>,
  ) => {
    if (!companyLogoLoading) {
      setCompanyLogoLoading(true);

      if (info.file.originFileObj) {
        try {
          const fileId = await uploadFile(info.file);
          const fullUrl = `${marketplaceUploadUrl}${fileId}`;

          setCompanyLogoImageUrl(fileId);
          setFullCompanyLogoImageUrl(fullUrl);
          formRef.current?.setFieldsValue({
            companyLogoDocumentPhoto: info.file,
          });
        } catch (error) {
          console.error("Error uploading company logo:", error);
          message.error("Upload failed.");
        } finally {
          setCompanyLogoLoading(false);
        }
      }
    }
  };

  const uploadCompanyLogoButton = (
    <div className="cursor-pointer border-0 bg-transparent flex flex-col items-center justify-center">
      {companyLogoLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="mt-2">Upload</div>
    </div>
  );

  const uploadFile = async (file: UploadFile) => {
    const formData = new FormData();
    formData.append("file", file.originFileObj as Blob);

    try {
      const response = await axiosInstance.post(
        `${apiUrl}/companies/${companyInfo?.id}/upload`,
        formData,
      );

      return response.data.id;
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  };

  const onFinish = async (values: FormValues) => {
    const filteredAddresses =
      values.companyAddresses?.filter(
        (a) => a.address && a.address.trim() !== "",
      ) || [];

    const photoGalleryUrls = imgFileList.map(
      (file) => file.url || file.response?.id,
    );

    const requestBody = {
      logo_url: companyLogoImageUrl,
      industry: values.industry,
      scale: values.companySize,
      description:
        values.companyDescription === "" ? null : values.companyDescription,
      business_fields: values.businessField,
      pic_urls: photoGalleryUrls,
      addresses:
        filteredAddresses.length > 0
          ? filteredAddresses.map((a) => a.address)
          : [],
    };

    console.log("requestBody", requestBody);
    try {
      const response = await axiosInstance.put(
        `${apiUrl}/companies/${companyInfo?.id}/advanced`,
        requestBody,
      );

      if (response.status === 200) {
        message.success("Company information updated successfully.");
        navigate("/companies/homepage", { state: { refetch: true } });
      } else {
        message.error("Failed to update company information");
      }
    } catch (error) {
      console.error("Error updating company information:", error);
      message.error("An error occurred while updating company information");
    }
  };

  const [form] = Form.useForm();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [imgFileList, setImgFileList] = useState<UploadFile[]>([]);

  const handlePreviewClose = () => {
    setPreviewOpen(false);
    setPreviewImage("");
  };

  const sensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 10 },
  });

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setImgFileList((prev) => {
        const activeIndex = prev.findIndex((i) => i.uid === active.id);
        const overIndex = prev.findIndex((i) => i.uid === over?.id);
        const newFileList = arrayMove(prev, activeIndex, overIndex);

        // Update form state with the new order
        form.setFieldsValue({ mediaFiles: newFileList });

        return newFileList;
      });
    }
  };

  const handleImgPreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    form.setFieldsValue({ mediaFiles: newFileList });
    setImgFileList(newFileList);
  };

  const beforeUpload = (file: FileType) => {
    const isImage = file.type.startsWith("image/");
    const isVideo = file.type.startsWith("video/");
    const isLt5M = file.size / 1024 / 1024 < 5;
    const isLt20M = file.size / 1024 / 1024 < 20;

    if (isImage && !isLt5M) {
      message.error("Image must be smaller than 5MB!");
      return Upload.LIST_IGNORE;
    }

    if (isVideo && !isLt20M) {
      message.error("Video must be smaller than 20MB!");
      return Upload.LIST_IGNORE;
    }

    if (!isImage && !isVideo) {
      message.error("You can only upload image or video files!");
      return Upload.LIST_IGNORE;
    }

    return true;
  };

  const uploadButton = (
    <Button className="border-none bg-gray-200 py-[30px] px-7 h-auto flex flex-col items-center justify-center gap-2 rounded-sm">
      <PlusOutlined className="text-sm text-[#686868]" />
      <span className="text-sm text-[#686868] !ml-0">Upload</span>
    </Button>
  );

  return (
    <Row gutter={24} className="min-h-screen">
      <Col span={24} className="overflow-auto">
        <Card bordered className="rounded-lg">
          <Create
            title="Edit Company Homepage"
            headerButtons={() => <></>}
            footerButtons={() => (
              <>
                <Button
                  type="primary"
                  onClick={() => formRef.current?.submit()}
                >
                  Submit
                </Button>
              </>
            )}
          >
            <Row justify="center">
              <Col span={12}>
                <Form
                  ref={formRef}
                  name="companyHomepageForm"
                  onFinish={onFinish}
                  layout="horizontal"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                >
                  <Row key="Company Homepage" gutter={20}>
                    <Col span={24}>
                      <Form.Item label="Company Name" name="companyName">
                        <Input variant="filled" disabled />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label="Company Abbreviation"
                        name="companyAbbreviation"
                      >
                        <Input variant="filled" disabled />
                      </Form.Item>
                    </Col>

                    {/* Company Logo */}
                    <Col span={24}>
                      <Form.Item
                        label="Company Logo"
                        name="companyLogo"
                        rules={
                          companyLogoImageUrl
                            ? []
                            : [
                                {
                                  required: true,
                                  message:
                                    "Please upload the image of the company's logo",
                                },
                              ]
                        }
                      >
                        <Row justify="center">
                          <Col span={12}>
                            <Upload
                              name="companyLogoUpload"
                              listType="picture-card"
                              className="avatar-uploader"
                              showUploadList={false}
                              beforeUpload={beforeUpload}
                              onChange={handleCompanyLogoImageChange}
                              accept="image/*"
                              customRequest={preventAutoUpload}
                            >
                              {fullCompanyLogoImageUrl ? (
                                <img
                                  src={fullCompanyLogoImageUrl}
                                  alt="Company Logo"
                                  className="w-full h-full object-contain"
                                />
                              ) : (
                                uploadCompanyLogoButton
                              )}
                            </Upload>
                          </Col>
                          <Col span={12}>
                            <div>
                              <p>Recommended image dimensions are 300*300.</p>
                              <p>The file size should not exceed 5MB.</p>
                            </div>
                          </Col>
                        </Row>
                      </Form.Item>
                    </Col>

                    {/* Industry */}
                    <Col span={24}>
                      <Form.Item
                        label="Industry"
                        name="industry"
                        rules={[
                          {
                            required: true,
                            message: "Please select an industry",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          filterOption={(input, option) =>
                            option?.children
                              ? (option.children as unknown as string)
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              : false
                          }
                        >
                          {industryOptions.map((group) => (
                            <Select.OptGroup
                              key={group.key}
                              label={group.label}
                            >
                              {group.options.map((option) => (
                                <Select.Option
                                  key={option.key}
                                  value={option.value}
                                >
                                  {option.label}
                                </Select.Option>
                              ))}
                            </Select.OptGroup>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    {/* Company Size */}
                    <Col span={24}>
                      <Form.Item
                        label="Company Size"
                        name="companySize"
                        rules={[
                          {
                            required: true,
                            message: "Please select the company size",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          filterOption={(input, option) =>
                            option?.children
                              ? (option.children as unknown as string)
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              : false
                          }
                        >
                          {companySizeOptions.map((group) => (
                            <OptGroup key={group.key} label={group.label}>
                              {group.options.map((option) => (
                                <Option key={option.key} value={option.value}>
                                  {option.label}
                                </Option>
                              ))}
                            </OptGroup>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    {/* Company Description */}
                    <Col span={24}>
                      <Form.Item
                        label="Company Description"
                        name="companyDescription"
                      >
                        <TextArea showCount maxLength={1000} rows={4} />
                      </Form.Item>
                    </Col>

                    {/* Business Field */}
                    <Col span={24}>
                      <Form.Item
                        label={`Business Field (Max: ${MAX_BUSINESS_FIELDS})`}
                        name="businessField"
                      >
                        <Select
                          showSearch
                          mode="multiple"
                          className="w-full"
                          placeholder="Select the company's business field"
                          options={skillOptions}
                          value={selectedBusinessField}
                          onChange={handleBusinessFieldChange}
                          maxCount={MAX_BUSINESS_FIELDS}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="photoGallery"
                        label={
                          <span className="whitespace-normal break-words">
                            Company Photo Album (Max: 20)
                          </span>
                        }
                        valuePropName="fileList"
                        getValueFromEvent={(e) => {
                          return e?.fileList;
                        }}
                      >
                        <DndContext
                          sensors={[sensor]}
                          onDragEnd={handleDragEnd}
                        >
                          <SortableContext
                            items={imgFileList.map((file) => file.uid)}
                            strategy={verticalListSortingStrategy}
                          >
                            <Upload
                              multiple={true}
                              maxCount={20}
                              action={`${apiUrl}/companies/${companyInfo?.id}/upload`}
                              listType="picture-card"
                              fileList={imgFileList}
                              onPreview={handleImgPreview}
                              onChange={handleChange}
                              beforeUpload={beforeUpload}
                              itemRender={(originNode, file) => (
                                <DraggableUploadListItem
                                  originNode={originNode}
                                  file={file}
                                />
                              )}
                              headers={{
                                authorization: `Bearer ${localStorage.getItem(
                                  TOKEN_KEY,
                                )}`,
                              }}
                            >
                              {imgFileList.length >= 20 ? null : uploadButton}
                            </Upload>
                          </SortableContext>
                        </DndContext>
                        {previewImage && (
                          <Image
                            wrapperStyle={{ display: "none" }}
                            preview={{
                              visible: previewOpen,
                              onVisibleChange: (visible) =>
                                setPreviewOpen(visible),
                              afterOpenChange: (visible) =>
                                !visible && setPreviewImage(""),
                            }}
                            src={previewImage}
                          />
                        )}
                      </Form.Item>
                      {previewImage &&
                        (previewImage.match(
                          `\\.(?:${videoExtensionsRegex})$`,
                        ) ? (
                          <Space
                            className="w-full aspect-[16/9] absolute"
                            key={previewImage}
                            onClick={() => setPreviewOpen(true)}
                          >
                            <Modal
                              visible={previewOpen}
                              footer={null}
                              onCancel={handlePreviewClose}
                              styles={{
                                content: {
                                  background: "transparent",
                                  padding: 0,
                                },
                              }}
                              width="50%"
                            >
                              <ReactPlayer
                                url={previewImage}
                                controls
                                width="100%"
                                height="100%"
                                config={{
                                  file: {
                                    attributes: {
                                      onContextMenu: (e: React.MouseEvent) =>
                                        e.preventDefault(),
                                      controlsList: "nodownload",
                                    },
                                  },
                                }}
                              />
                            </Modal>
                          </Space>
                        ) : (
                          <Image
                            wrapperStyle={{ display: "none" }}
                            preview={{
                              visible: previewOpen,
                              onVisibleChange: (visible) =>
                                setPreviewOpen(visible),
                              afterOpenChange: (visible) =>
                                !visible && setPreviewImage(""),
                            }}
                            src={previewImage}
                          />
                        ))}
                    </Col>

                    {/* Company Address */}
                    <Col span={24}>
                      <Form.List name="companyAddresses" initialValue={[""]}>
                        {(fields, { add, remove }, { errors }) => (
                          <>
                            <Form.Item label="Company Address">
                              {fields.map(
                                (
                                  { key, name, fieldKey, ...restField },
                                  index,
                                ) => (
                                  <Space
                                    key={key}
                                    className="flex mb-2"
                                    align="baseline"
                                  >
                                    <Form.Item
                                      {...restField}
                                      name={[name, "address"]}
                                      fieldKey={[
                                        fieldKey as React.Key,
                                        "address",
                                      ]}
                                      noStyle
                                    >
                                      <Input
                                        maxLength={500}
                                        showCount
                                        placeholder="Enter address"
                                      />
                                    </Form.Item>
                                    {fields.length > 1 ? (
                                      <MinusCircleOutlined
                                        onClick={() => remove(name)}
                                      />
                                    ) : null}
                                  </Space>
                                ),
                              )}
                              <Form.Item>
                                <Button
                                  type="dashed"
                                  onClick={() => add()}
                                  icon={<PlusCircleOutlined />}
                                  className="w-1/12"
                                  disabled={fields.length >= 10}
                                />
                                <Form.ErrorList errors={errors} />
                              </Form.Item>
                            </Form.Item>
                          </>
                        )}
                      </Form.List>
                    </Col>

                    <Form.Item
                      name="authorizationCheckbox"
                      valuePropName="checked"
                      rules={[
                        {
                          validator: (_, value) =>
                            value
                              ? Promise.resolve()
                              : Promise.reject(
                                  new Error(
                                    "You must certify that you are an authorized representative of this organization.",
                                  ),
                                ),
                        },
                      ]}
                      wrapperCol={{ offset: 8, span: 16 }}
                    >
                      <Checkbox>
                        I certify that I am an authorized representative of this
                        organization and have the right to create and manage its
                        homepage.
                      </Checkbox>
                    </Form.Item>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Create>
        </Card>
      </Col>
    </Row>
  );
};
