import { Col, Row } from "antd";

interface ISelectCollectionOptionProps {
  collectionState: string;
  setCollectionState: (value: string) => void;
}

export const SelectCollectionOption: React.FC<ISelectCollectionOptionProps> = ({
  collectionState,
  setCollectionState,
}) => {
  return (
    <Row className="flex flex-row rounded-full bg-[#292D31] p-1 w-fit text-white">
      <Col
        className={`px-3.5 py-1.5 rounded-full ${
          collectionState === "artworks" && "bg-white text-[#1D1F22]"
        }`}
        onClick={() => setCollectionState("artworks")}
      >
        Artworks
      </Col>
      <Col
        className={`px-3.5 py-1.5 rounded-full ${
          collectionState === "bookmark" && "bg-white text-[#1D1F22]"
        }`}
        onClick={() => setCollectionState("bookmark")}
      >
        Bookmark
      </Col>
    </Row>
  );
};
