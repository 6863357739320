import Author from "@/components/discover/homepage/Author";
import { FilterBox } from "@/components/discover/homepage/FilterBox";
import Portfolio from "@/components/discover/homepage/Portfolio";
import { useArtworkStore, useGlobalStore } from "@/stores";
import {
  LeftOutlined,
  LoadingOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { IResourceComponentsProps } from "@refinedev/core";
import { Carousel, Col, Image, Row, Spin } from "antd";
import { CarouselRef } from "antd/es/carousel";
import React, { useEffect, useRef, useState } from "react";
const RESOURCES_BASE_URL = import.meta.env.VITE_STATIC_ASSETS_BASE_URL;
export const DiscoverHomepage: React.FC<IResourceComponentsProps> = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [portfolioFilter, setPortfolioFilter] = useState<string>("Default");
  const [authorFilter, setAuthorFilter] = useState<string>("All");
  const { activeCol, updateActiveCol } = useGlobalStore((state) => state);
  const { artworkSearch } = useArtworkStore((state) => state);
  const slider = useRef<CarouselRef>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const panels: string[] = [
    `${RESOURCES_BASE_URL}banner/banner0911_1_1.png`,
    `${RESOURCES_BASE_URL}banner/banner0911_2_1x_new.png`,
    `${RESOURCES_BASE_URL}banner/banner0831_3.png?v=1`,
  ];
  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "150px", // Default centerPadding for larger screens
    responsive: [
      {
        breakpoint: 992,
        settings: {
          centerPadding: "120px",
        },
      },
      {
        breakpoint: 768,
        settings: {
          centerPadding: "80px",
        },
      },
      {
        breakpoint: 554,
        settings: {
          centerPadding: "50px",
        },
      },
    ],
  };

  useEffect(() => {
    if (artworkSearch !== "") {
      updateActiveCol("portfolio");
    }
  }, [artworkSearch, updateActiveCol]);

  return (
    <Col ref={containerRef} className="h-full overflow-auto">
      <Col>
        <Col className="absolute z-10000 text-center">
          {isLoading && (
            <span className="text-gray-400">
              <Spin indicator={<LoadingOutlined spin />} /> Data loading...
            </span>
          )}
        </Col>
        <Col className="w-full mx-auto my-6">
          <div className="discover-carousel-arrow !h-full">
            <LeftOutlined
              className="custom-arrow"
              onClick={() => slider.current?.prev()}
            />
            <RightOutlined
              className="custom-arrow"
              onClick={() => slider.current?.next()}
            />
          </div>
          <Carousel
            className="discover-carousel"
            ref={slider}
            autoplay
            dots={false}
            infinite={settings.infinite}
            slidesToShow={settings.slidesToShow}
            slidesToScroll={settings.slidesToScroll}
            centerMode={settings.centerMode}
            arrows={false}
            centerPadding={settings.centerPadding}
            responsive={settings.responsive}
            autoplaySpeed={5000}
          >
            {panels.map((image) => (
              <div key={image} className="px-2">
                <Image
                  preview={false}
                  src={image}
                  alt={`slide-${image}`}
                  width="100%"
                  // height="350px"
                  className="rounded-xl h-80 cursor-pointer"
                  onClick={() => {
                    const baseImageName = image.split("?")[0];
                    if (baseImageName.endsWith("_2.png")) {
                      window.open(
                        "https://www.leylinepro.com/mktp/discover/portfolio/artwork_80708377-65e9-4a33-8760-d1eb594e4c4e",
                        "_blank",
                      );
                    } else if (baseImageName.endsWith("_3.png")) {
                      window.open(
                        "https://www.forbes.com/sites/amirhusain/2024/08/05/ai-takes-center-stage-in-media-production/",
                        "_blank",
                      );
                    } else {
                      window.open(
                        "https://m3d60c6dc7z.larksuite.com/share/base/form/shrusYobfMagv7kTrTlRV9g2Jxb",
                        "_blank",
                      );
                    }
                  }}
                />
              </div>
            ))}
          </Carousel>
        </Col>
        <Col className="px-6 py-4">
          {activeCol === "portfolio" ? (
            <Portfolio
              containerRef={containerRef}
              portfolioFilter={portfolioFilter}
              artworkSearch={artworkSearch}
            />
          ) : (
            <Author containerRef={containerRef} authorFilter={authorFilter} />
          )}
        </Col>
        <Col className="flex justify-center">
          <FilterBox
            portfolioFilter={portfolioFilter}
            setPortfolioFilter={setPortfolioFilter}
            authorFilter={authorFilter}
            setAuthorFilter={setAuthorFilter}
          />
        </Col>
      </Col>
    </Col>
  );
};
