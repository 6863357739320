import { CustomCard } from "@/components/customComponents/CustomCard";
import { ICompany } from "@/pages/discover/types";
import { useGlobalStore } from "@/stores";
import { getImgUrl } from "@/utils/common";
import { videoExtensions } from "@/utils/identifyFiles";
import { SettingOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Image, Row, Space } from "antd";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
const videoExtensionsRegex = videoExtensions.join("|");
interface ICompanyIntroductionProps {
  user_role: string;
  companyInfo: ICompany;
  authorId: string;
  userId: string;
}

export const CompanyIntroduction: React.FC<ICompanyIntroductionProps> = ({
  user_role,
  companyInfo,
  authorId,
  userId,
}) => {
  const { currentRole } = useGlobalStore((state) => state);
  const navigate = useNavigate();
  const renderAddresses = () => {
    return (
      <CustomCard title="Address">
        <Col>
          {companyInfo.addresses?.map((address, index) => (
            // biome-ignore lint:
            <div key={address + index}>
              {index !== 0 && <Divider className="bg-white opacity-10 my-5" />}
              {address}
            </div>
          ))}
        </Col>
      </CustomCard>
    );
  };

  const renderMediaGallery = () => {
    return (
      <CustomCard title="Company Photo Album">
        <Row gutter={16}>
          {companyInfo.pic_urls?.map((url, index) => (
            // biome-ignore lint:
            <Col key={url + index} span={6} className="flex flex-row gap-4">
              {url.match(new RegExp(`\\.(?:${videoExtensionsRegex})$`, "i")) ? (
                <div className="w-full aspect-[16/9]">
                  <ReactPlayer
                    url={getImgUrl(url) as string}
                    controls
                    width="100%"
                    height="100%"
                    config={{
                      file: {
                        attributes: {
                          onContextMenu: (e: React.MouseEvent) =>
                            e.preventDefault(),
                          controlsList: "nodownload",
                        },
                      },
                    }}
                  />
                </div>
              ) : (
                <Image
                  className="aspect-[16/9] object-cover"
                  width={"100%"}
                  height={"100%"}
                  preview={true}
                  src={getImgUrl(url) || ""}
                />
              )}
            </Col>
          ))}
        </Row>
      </CustomCard>
    );
  };
  return (
    <div className="flex flex-col">
      {authorId === userId && user_role === currentRole && (
        <div className="-mt-3 mb-3.5 text-right">
          <Button
            className="py-2 px-6 h-full rounded-full bg-[#00ADAE] text-white text-sm border-none"
            onClick={() => navigate("/companies/edit-homepage")}
          >
            <SettingOutlined />
            <span>Edit Introduction</span>
          </Button>
        </div>
      )}
      <div className="flex flex-col gap-5">
        <CustomCard title="Company Description">
          <div>
            {companyInfo.description || "No profile information available."}
          </div>
        </CustomCard>
        <CustomCard title="Business Field">
          <div className="flex flex-row gap-2 flex-wrap">
            {companyInfo?.business_fields &&
            companyInfo.business_fields.length > 0 ? (
              companyInfo.business_fields.map((bField, index) => (
                <Space
                  // biome-ignore lint:
                  key={bField + index}
                  className="bg-[#404444] text-white rounded-full text-sm py-0.5 px-2 hover:bg-[#606464]"
                >
                  {bField}
                </Space>
              ))
            ) : (
              <span>No business field specified</span>
            )}
          </div>
        </CustomCard>
        <div>{renderMediaGallery()}</div>
        <div>{renderAddresses()}</div>
      </div>
    </div>
  );
};
