import { UserType } from "@/__generated__/graphql";
import { CompanyIcon, DefaultUserIcon } from "@/components/customComponents";
import { CustomInput } from "@/components/customComponents/CustomInput";
import { JobPanel } from "@/components/discover/workDetail/JobPanel";
import { createCommentAPI, createLikeAPI } from "@/pages/discover/api";
import {
  ICommentNotificationData,
  ICompany,
  ICreateCommentData,
  IJobData,
  IUser,
} from "@/pages/discover/types";
import { useArtworkStore, useGlobalStore } from "@/stores";
import { getImgUrl } from "@/utils/common";
import { formatToLongStyle } from "@/utils/date";
import { DeleteOutlined, LikeFilled, MessageFilled } from "@ant-design/icons";
import {
  Avatar,
  Badge,
  Button,
  Col,
  Image,
  Row,
  Space,
  Typography,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

interface ICommentDrawerItemProps {
  setNotifData: (data: string) => void;
  commentItem: ICommentNotificationData;
}
// CommentDrawerItem component using mock data
export const CommentDrawerItem: React.FC<ICommentDrawerItemProps> = ({
  setNotifData,
  commentItem,
}) => {
  const { currentRole } = useGlobalStore();
  const { updateArtworkDetailArtworkId, updateArtworkDetailModalVisible } =
    useArtworkStore();
  const [jobPanelVisible, setJobPanelVisible] = useState(false);
  const [selectJob, setSelectJob] = useState<IJobData>();
  const [commentContent, setCommentContent] = useState<string>("");
  const [replyInputVisible, setReplyInputVisible] = useState(false);
  const [commentItemState, setCommentItemState] =
    useState<ICommentNotificationData>();

  useEffect(() => {
    setCommentItemState(commentItem);
  }, [commentItem]);

  const onCommentLike = async (commentId?: string) => {
    if (!currentRole || !commentId) return;
    const response = await createLikeAPI(commentId, "COMMENT", currentRole);
    // update like status
    if (response) {
      setCommentItemState((prev: ICommentNotificationData | undefined) => {
        if (!prev) return prev;
        return {
          ...prev,
          target: {
            ...prev.target,
            is_liked: true,
            liked_count: (prev.target.liked_count ?? 0) + 1,
          },
        };
      });
      setNotifData(`like_${commentId}`);
    } else {
      message.error("Failed to like comment");
    }
  };

  const onReplyConfirm = async (artworId?: string, commentId?: string) => {
    if (!currentRole || !artworId || !commentId) return;
    if (!commentContent) {
      message.error("Please enter a comment");
      return;
    }

    const requestData: ICreateCommentData = {
      artwork_id: artworId,
      content: commentContent,
      job_id: selectJob ? selectJob.id : "",
      parent_id: commentId,
    };
    const response = await createCommentAPI(requestData, currentRole);
    if (response.id) {
      setCommentContent("");
    } else {
      message.error("Failed to add comment");
    }
    setReplyInputVisible(false);
    setSelectJob(undefined);
  };

  const onCommentReply = () => {
    setReplyInputVisible(true);
  };

  const onDeleteJob = () => {
    setSelectJob(undefined);
  };

  const goToArtworkDetail = (id: string) => {
    updateArtworkDetailArtworkId(id);
    updateArtworkDetailModalVisible(true);
  };

  return (
    <Row className="flex flex-1 w-full justify-between gap-2.5 px-[15px]">
      <Link
        to={`/discover/author/${commentItemState?.target.user_id}?role=${commentItemState?.target.role}`}
      >
        <div className="relative">
          <Avatar
            className={`object-cover ${
              commentItemState?.target.role === UserType.Company &&
              "border-2 border-solid border-[#03B3B4]"
            }`}
            size={40}
            src={
              commentItemState?.target.role === UserType.Company ? (
                (commentItemState?.author_info as ICompany).logo_url ? (
                  getImgUrl(
                    (commentItemState?.author_info as ICompany).logo_url,
                  )
                ) : (
                  <DefaultUserIcon />
                )
              ) : (commentItemState?.author_info as IUser)?.avatar_url ? (
                getImgUrl((commentItemState?.author_info as IUser).avatar_url)
              ) : (
                <DefaultUserIcon />
              )
            }
          />
          {commentItemState?.target.role === UserType.Company && (
            <Space className="absolute z-10 left-1/2 top-[-18%] transform -translate-x-1/2">
              <CompanyIcon width="16px" height="16px" />
            </Space>
          )}
        </div>
      </Link>
      <Row className="flex flex-col flex-1 gap-2">
        <Row className="flex flex-row gap-2.5 flex-1">
          <Row className="flex flex-col flex-1">
            <Col className="flex flex-row justify-between">
              <Link
                to={`/discover/author/${commentItemState?.target.user_id}?role=${commentItemState?.target.role}`}
              >
                <Typography.Text className="text-white text-opacity-85 text-base">
                  {commentItemState?.target.role === UserType.Company
                    ? (commentItemState?.author_info as ICompany).shortname
                    : (commentItemState?.author_info as IUser)?.user_name}
                </Typography.Text>
              </Link>
            </Col>
            <Typography.Paragraph
              className="text-[#606264] text-sm w-full !mb-0.5"
              ellipsis={{ rows: 1 }}
            >
              {commentItemState?.message}{" "}
              {formatToLongStyle(commentItemState?.created_at)}
            </Typography.Paragraph>
            <Col>
              <Typography.Paragraph
                className="text-white text-opacity-85 text-sm w-full !mb-0.5"
                ellipsis={{ rows: 2 }}
              >
                {commentItemState?.target.content}
              </Typography.Paragraph>
            </Col>
            {commentItemState?.message === "Replied to your comment" && (
              <Typography.Paragraph
                className="text-[#606264] text-sm w-full !mb-0.5 flex gap-2"
                ellipsis={{ rows: 1 }}
              >
                <Badge color="white" />
                <span>{commentItemState?.target.parent_content}</span>
              </Typography.Paragraph>
            )}
          </Row>
          <Row>
            <Col
              onClick={() =>
                goToArtworkDetail(commentItemState?.artwork.id || "")
              }
            >
              <Image
                preview={false}
                width="95px"
                height="83px"
                className="rounded-sm"
                src={
                  getImgUrl(commentItemState?.artwork.cover_picture) ||
                  "https://placehold.co/95x83"
                }
              />
            </Col>
          </Row>
        </Row>
        {selectJob && (
          <Row className="flex flex-row gap-2 rounded-sm bg-[#343638] p-3 relative">
            <Image
              preview={false}
              width="80px"
              height="60px"
              className="rounded-sm"
              src={
                getImgUrl(selectJob.project_thumbnail_url) ||
                "https://placehold.co/64x64"
              }
            />
            <Col className="flex-1 flex flex-col gap-2.5 justify-center">
              <Typography.Text className="text-white text-opacity-85 font-bold text-base">
                {selectJob.project_name}
              </Typography.Text>
              <Typography.Text className="text-[#606264] text-sm">
                {selectJob.location
                  ? `${selectJob.location} / ${selectJob.remote_option} / ${selectJob.employment_type}`
                  : `${selectJob.remote_option} / ${selectJob.employment_type}`}
              </Typography.Text>
            </Col>
            <DeleteOutlined
              className="absolute right-2 top-2 text-[#606264]"
              onClick={onDeleteJob}
            />
          </Row>
        )}
        <Row>
          {replyInputVisible ? (
            <Col className="flex flex-row justify-between flex-1 gap-4">
              <CustomInput
                placeholder="Reply user name"
                className="!rounded-full !w-full !bg-transparent !border-[1px] !border-solid !border-[#606264]"
                maxLength={500}
                value={commentContent}
                onChange={(e) => setCommentContent(e.target.value)}
              />
              <Col className="flex flex-row gap-1">
                {jobPanelVisible && (
                  <div className="absolute top-12 right-0 z-10">
                    <JobPanel
                      setJobPanelVisible={setJobPanelVisible}
                      setSelectJob={setSelectJob}
                    />
                  </div>
                )}
                {currentRole === UserType.Company && (
                  <Space
                    className="border-[1px] border-solid border-[#606264] text-[#606264] w-[30px] h-[30px] text-sm rounded-full flex justify-center cursor-pointer"
                    onClick={() => setJobPanelVisible(!jobPanelVisible)}
                  >
                    Job
                  </Space>
                )}
                <Button
                  className="py-1 px-4 h-full rounded-full bg-transparent border-solid border-[1px] border-[#606264] text-[#999999] text-sm"
                  onClick={() => {
                    setReplyInputVisible(false);
                    setSelectJob(undefined);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="bg-[#00ADAE] px-4 py-1 border-none rounded-full text-white text-base"
                  onClick={() =>
                    onReplyConfirm(
                      commentItemState?.artwork.id,
                      commentItemState?.target.id,
                    )
                  }
                >
                  Confirm
                </Button>
              </Col>
            </Col>
          ) : (
            <Col className="text-[#686868] flex flex-row gap-5">
              <Col className="flex gap-1">
                <LikeFilled
                  className={`${
                    commentItemState?.target.is_liked && "text-[#fe2c55]"
                  } cursor-pointer`}
                  onClick={() => onCommentLike(commentItemState?.target.id)}
                />
                <span>{commentItemState?.target.liked_count}</span>
              </Col>
              <Col className="cursor-pointer" onClick={onCommentReply}>
                <MessageFilled /> <span>Reply</span>
              </Col>
            </Col>
          )}
        </Row>
      </Row>
    </Row>
  );
};
