import { UserType } from "@/__generated__/graphql";
import { CompanyAvatar } from "@/components/businessComponents";
import AuthorItem from "@/components/discover/homepage/AuthorItem";
import { useUserData } from "@/hooks";
import { IAuthorItem, ICompany, IUser } from "@/pages/discover/types";
import { RoleType } from "@/utils/type";
import { padImageUrl } from "@/utils/upload";
import { Image } from "antd";
import { FC, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import userDefaultImg from "/images/defaultAvatar.png";

interface Applicant {
  id: string;
  applicant_company: ICompany;
  applicant_user: IUser;
  applicant_role: RoleType;
  authorItem: IAuthorItem;
}

interface Props {
  data: {
    items: Applicant[];
    total: number;
  };
}

const maxCount = 8;

const Applicants: FC<Props> = ({ data }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const profile = useUserData();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    setSelectedIndex(0);
  }, [data]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const currentArtist = useMemo(() => {
    return data?.items?.[selectedIndex]?.authorItem;
  }, [selectedIndex, data]);

  return data ? (
    <div>
      <div className="relative flex mb-[22px]">
        {data.items.map((x, i) =>
          i <= maxCount - 1 ? (
            <div
              key={x.id}
              className="flex rounded-full mr-[10px] relative cursor-pointer hover:scale-110"
            >
              {x.applicant_role === RoleType.company ? (
                <CompanyAvatar
                  size="middle"
                  {...x.applicant_company}
                  onClick={() => setSelectedIndex(i)}
                />
              ) : (
                <Image
                  width={40}
                  height={40}
                  className="rounded-full border-[1px] border-solid border-white"
                  preview={false}
                  src={
                    x.applicant_user.avatar_url
                      ? padImageUrl(x.applicant_user.avatar_url)
                      : userDefaultImg
                  }
                  onClick={() => setSelectedIndex(i)}
                />
              )}
              {selectedIndex === i && (
                <span className=" absolute w-[calc(100%+4px)] h-[calc(100%+4px)] rounded-full border-[1px] border-solid border-[#03B3B4] -left-[2px] -top-[2px]" />
              )}
            </div>
          ) : null,
        )}
        {data.total - maxCount > 0 && (
          <span className="rounded-full shrink-0 text-white w-[40px] h-[40px] leading-10 bg-[#4d4f51] text-center">
            +{data.total - maxCount}
          </span>
        )}
        <div
          className="absolute -bottom-7 text-[#232529]"
          style={{ marginLeft: `${50 * selectedIndex + 14}px` }}
        >
          &#9650;
        </div>
      </div>
      {!!currentArtist && (
        <AuthorItem
          authorItem={currentArtist}
          comState="follow"
          stopPropagation
        />
      )}
    </div>
  ) : null;
};

export default Applicants;
