import { generateTimezoneOptions, guessUserTimezone } from "@/utils/date";
import { axiosInstance } from "@/utils/http";
import { Create } from "@refinedev/antd";
import {
  Button,
  Card,
  Col,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Switch,
  Typography,
  message,
} from "antd";
import React, { useEffect, useRef, useState } from "react";

const { Option } = Select;
const { Text } = Typography;

export const SettingsPage: React.FC = () => {
  const formRef = useRef<FormInstance>(null);
  const [form] = Form.useForm();
  const [openNotification, setOpenNotification] = useState(true);

  const timezoneOptions = generateTimezoneOptions();

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await axiosInstance.get("/users/settings");
        let timezone = response.data.timezone;
        if (!timezone) {
          timezone = guessUserTimezone();
        }
        form.setFieldsValue({
          email: response.data.email,
          timezone,
        });
        setOpenNotification(response.data.is_im_email_subscribed);
        localStorage.setItem("userTimezone", timezone);
      } catch (error) {
        console.error("Failed to fetch user profile: ", error);
        message.error("Failed to load user profile");
      }
    };

    fetchUserProfile();
  }, [form]);

  const onChangeNotification = (e: boolean) => {
    setOpenNotification(e);
    handleSave({
      is_im_email_subscribed: e,
    });
  };

  const handleSave = async (saveArgs?: { is_im_email_subscribed: boolean }) => {
    try {
      const values = await form.validateFields();
      console.log("Form values:", values);

      await axiosInstance.put(
        "/users/settings",
        saveArgs ?? {
          email: values.email,
          timezone: values.timezone,
        },
      );

      localStorage.setItem("userTimezone", values.timezone);

      message.success("Setting updated successfully! ");
    } catch (error) {
      console.log("Update Failed:", error);
      message.error("Failed to update setting.");
    }
  };

  return (
    <Row gutter={24} className="min-h-screen">
      <Col span={24} className="overflow-auto">
        <Card bordered className="rounded-lg">
          <Create
            title="Settings"
            headerButtons={() => <></>}
            footerButtons={() => (
              <Button
                type="primary"
                onClick={() => formRef.current?.submit()}
                style={{ float: "right" }}
              >
                Save
              </Button>
            )}
          >
            <Row justify="center">
              <Col span={12}>
                <Form
                  ref={formRef}
                  form={form}
                  layout="horizontal"
                  labelCol={{ span: 7 }}
                  initialValues={{
                    language: "English",
                  }}
                  onFinish={() => handleSave()}
                >
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      { required: true, message: "Please enter your email" },
                    ]}
                  >
                    <Input disabled />
                  </Form.Item>

                  <Form.Item
                    label="Language"
                    name="language"
                    rules={[
                      { required: true, message: "Please select a language" },
                    ]}
                    extra={<Text type="secondary">Interface Language</Text>}
                  >
                    <Select defaultValue="English">
                      <Option value="English">English</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="Timezone"
                    name="timezone"
                    rules={[
                      { required: true, message: "Please select a timezone" },
                    ]}
                    extra={
                      <Text type="secondary">
                        Default Timezone Settings for LeyLine
                      </Text>
                    }
                  >
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        option?.children
                          ? (option.children as unknown as string)
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          : false
                      }
                    >
                      {timezoneOptions.map((option) => (
                        <Option key={option.value} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Create>
          <div className="w-1/2 pt-4 m-auto flex">
            <div
              className="shrink-0 mr-1 text-right"
              style={{ width: "29.17%" }} // 7/24
            >
              IM Email Notification:
            </div>
            <div>
              <Switch
                value={openNotification}
                onChange={onChangeNotification}
                checkedChildren="ON"
                unCheckedChildren="OFF"
              />
              <div className="text-gray-400">
                Once enabled, all chat messages you receive will also be sent to
                you via email.
              </div>
            </div>
          </div>
        </Card>
      </Col>
    </Row>
  );
};
