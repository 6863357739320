import { getCompanyJobsAPI } from "@/pages/discover/api";
import { IJobData, IRequestCompanyJobsData } from "@/pages/discover/types";
import { getImgUrl } from "@/utils/common";
import { SearchOutlined } from "@ant-design/icons";
import { ConfigProvider, Image, Input, Row, Space, Typography } from "antd";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";

interface IJobPanelProps {
  setJobPanelVisible: (state: boolean) => void;
  setSelectJob: (job: IJobData) => void;
}

export const JobPanel: React.FC<IJobPanelProps> = ({
  setJobPanelVisible,
  setSelectJob,
}) => {
  const [requestData, setRequestData] = useState<IRequestCompanyJobsData>({
    page: 1,
    size: 100,
  });
  const [jobsListData, setJobsListData] = useState<IJobData[]>([]);
  const getCompanyJobs = useCallback(async () => {
    const response = await getCompanyJobsAPI(requestData);
    setJobsListData(response.list);
  }, [requestData]);

  useEffect(() => {
    getCompanyJobs();
  }, [getCompanyJobs]);

  const onJobSearch = debounce((value: string) => {
    setRequestData((prev) => ({ ...prev, name: value }));
  }, 1000);

  const onSelectJob = (job: IJobData) => {
    setSelectJob(job);
    setJobPanelVisible(false);
  };

  return (
    <Row className="flex flex-row gap-2 flex-1 bg-[#343638] rounded-xl w-[259px] shadow-md p-3">
      <div className="max-h-[130px] overflow-auto">
        {jobsListData?.length === 0 ? (
          <div className="w-full text-center text-[#999999]">No Job</div>
        ) : (
          <Row className="flex flex-col gap-3">
            {jobsListData?.map((job) => (
              <Space
                key={job.id}
                className="flex flex-row gap-2 items-center w-full"
                onClick={() => onSelectJob(job)}
              >
                <Image
                  className="rounded-sm"
                  width="46px"
                  height="32px"
                  preview={false}
                  src={
                    getImgUrl(job.project_thumbnail_url) ||
                    "https://placehold.co/64x64"
                  }
                />
                <Typography.Text className="text-[#999999] text-basen hover:text-white">
                  {job.title}
                </Typography.Text>
              </Space>
            ))}
          </Row>
        )}
      </div>
      <ConfigProvider
        theme={{
          token: {
            colorTextPlaceholder: "#686868",
          },
        }}
      >
        <Input
          placeholder="Search"
          prefix={<SearchOutlined className="text-lg text-[#686868]" />}
          className={
            "gap-[10px] py-[10px] pl-[5px] text-xs text-white bg-transparent border-[1px] border-solid border-[#606264] rounded-full hover:bg-transparent focus:bg-transparent focus-within:bg-transparent"
          }
          onChange={(e) => onJobSearch(e.target.value)}
        />
      </ConfigProvider>
    </Row>
  );
};
