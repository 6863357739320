import { IIdentity, IProject, userIdentityBody } from "@/interfaces";
import { padLeftImgUrl } from "@/utils/common";
import { formatToMiddleStyle } from "@/utils/date";
import { ptLink, ptLinkWithState } from "@/utils/helper";
import { jwtDecode } from "../../packages/jwt-decode";

import {
  HttpError,
  useGetIdentity,
  useOne,
  useParsed,
  useUpdate,
} from "@refinedev/core";
import {
  Button,
  Card,
  Col,
  Divider,
  Image,
  Row,
  Space,
  Spin,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { useEffect } from "react";
import { BsBuildings } from "react-icons/bs";

import { EnvironmentOutlined } from "@ant-design/icons";
import { Link, useFetcher, useLocation, useNavigate } from "react-router-dom";
const { Text, Title } = Typography;
const { Meta } = Card;
export const InvitationLanding = () => {
  const { params } = useParsed();
  const projectId = params?.project_id as string;
  const token = params?.token as string;
  const navigate = useNavigate();
  const { data: user } = useGetIdentity<IIdentity>();
  const { data: dataP, isLoading: isProjectLoading } = useOne<
    IProject,
    HttpError
  >({
    resource: "projects",
    id: `${projectId}/public`,
    queryOptions: {
      enabled: !!projectId,
    },
  });

  const projectInfo = (dataP?.data || {}) as IProject;

  const { mutate: joinProject } = useUpdate();

  const handleJoinProject = () => {
    if (!user) {
      navigate(
        `/login?redirectPath=${encodeURIComponent(
          `/staff/invitation${window.location.search}`,
        )}`,
      );
      return;
    }
    joinProject(
      {
        resource: "staff",
        id: `join?project_id=${projectId}&token=${token}`,
        values: {},
        meta: {
          method: "post",
        },
        dataProviderName: "PT",
        // @ts-ignore
        errorNotification: (error, values, resource) => {
          const msg = error?.errors?.detail || "Failed to join the project";
          return {
            message: msg,
            description: "Failed to join",
            type: "error",
          };
        },
        successNotification: (data, values, resource) => {
          return {
            message: "Successfully joined the project",
            description: "Successfully joined",
            type: "success",
          };
        },
      },
      {
        onSuccess: () => {
          window.location.assign(ptLink("projects?type=joined"));
        },
      },
    );
  };

  if (isProjectLoading) return <p>Loading...</p>;
  if (!isProjectLoading && !projectInfo?.name) return <p>Project not found</p>;
  return (
    <div style={{ backgroundColor: "#F7F9FD", height: "85vh" }}>
      <div className="flex flex-col md:flex-row justify-center items-center h-full">
        <div className="flex justify-center flex-1">
          <div id="center-main" className="flex flex-col justify-center">
            <h1 className="text-6xl font-bold mb-4">Project Invitation</h1>
            <Text className="text-3xl" type={"secondary"}>
              {projectInfo.user_name} invites you to join the project.
            </Text>
            {/* TODO: user already logined */}
            <Button
              type="primary"
              size="large"
              className="mt-4 mb-4 w-min"
              onClick={handleJoinProject}
            >
              {user?.id ? "Join Now" : "Join Immediately After Logging In"}
            </Button>
            <Text>
              New User{" "}
              <Link
                to={`/register?redirectPath=${encodeURIComponent(
                  `/staff/invitation${window.location.search}`,
                )}`}
              >
                <Text className="text-blue-500">Registration</Text>
              </Link>
            </Text>
          </div>
        </div>
        <Card
          className="h-full flex-1"
          cover={
            <Image
              className="w-full"
              alt="project cover"
              style={{
                objectFit: "cover",
                maxHeight: "15rem",
              }}
              src={padLeftImgUrl(projectInfo.thumbnail_url)}
              preview={false}
            />
          }
        >
          <Row gutter={20}>
            <Col span={20}>
              <Meta title={projectInfo?.name} />
              <p className="mt-4">
                <Text type="secondary">
                  {formatToMiddleStyle(projectInfo?.start_date)}~
                  {formatToMiddleStyle(projectInfo?.end_date)}
                </Text>
                <br />
              </p>
              <Text type="secondary">{projectInfo?.description}</Text>
            </Col>
            <Divider type="horizontal" />
            <Row className="w-full">
              <Col>
                {projectInfo?.logo_url ? (
                  <Image
                    src={padLeftImgUrl(projectInfo?.logo_url)}
                    alt={projectInfo?.name}
                    preview={false}
                    style={{
                      width: "75px",
                      height: "75px",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <div
                    style={{
                      width: "75px",
                      height: "75px",
                      backgroundColor: "#f0f0f0",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <BsBuildings size={30} color="#aaa" />
                  </div>
                )}
              </Col>
              <Col span={12} className="pl-2">
                <Title level={5}>{projectInfo?.shortname || ""}</Title>
                <Space>
                  <Text>
                    {projectInfo?.industry} {" | "}
                  </Text>
                  <Text>{projectInfo?.scale}</Text>
                </Space>
                <Row>
                  <Text type="secondary" className="pt-1">
                    <EnvironmentOutlined style={{ marginRight: "4px" }} />{" "}
                    {projectInfo?.country}
                  </Text>
                </Row>
              </Col>
            </Row>
            <p className="mt-4">
              {projectInfo?.focus_areas?.map((v) => {
                return (
                  <Tag color="#ADC6FF" className="mr-1 mb-1">
                    {v}
                  </Tag>
                );
              })}
            </p>
          </Row>
        </Card>
      </div>
    </div>
  );
};
