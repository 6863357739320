import JobDetail from "@/pages/enterprises/components/JobDetail";
import { IResourceComponentsProps, useParsed } from "@refinedev/core";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

export const PublicJobDetails: React.FC<IResourceComponentsProps> = () => {
  const navigate = useNavigate();
  const { id } = useParsed();

  return (
    <div className="p-6 h-full">
      <JobDetail
        id={id as string}
        onClose={() => {
          const prevPagePath = sessionStorage.getItem("prevPagePath");
          navigate(prevPagePath ? prevPagePath : "/enterprises/public-jobs");
        }}
      />
    </div>
  );
};
